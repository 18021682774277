import { Button, Card, Grid, Box, Stack, Typography, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../style.css';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import { CharacterMetadata, ContentState, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

export default function AboutUsEditor({ data, getAboutUs }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [htmlString, setHtmlString] = useState(data?.aboutUs);
  const [contentText, setContentText] = useState(data?.aboutUs);
  const [editMode, setEditMode] = useState(false);
  const [aboutUsTitle, setAboutUsTitle] = useState(data?.aboutUsTitle || 'About Us');

  const containerRef = useRef(null);

  const removeInlineStyles = (editorState, retainInlineStyles = []) => {
    const blocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .map((singleBlock) =>
        singleBlock.set(
          'characterList',
          singleBlock.getCharacterList().map((charMetaData) => {
            if (!charMetaData) {
              return charMetaData;
            }
            const entity = charMetaData.getEntity();
            const style = charMetaData.getStyle();
            return CharacterMetadata.create({
              entity,
              style: style.intersect(retainInlineStyles),
            });
          })
        )
      )
      .toArray();

    return EditorState.createWithContent(ContentState.createFromBlockArray(blocks));
  };

  const removeFormatting = () => {
    const newState = removeInlineStyles(editorState);
    setEditorState(newState);
  };

  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'indent';
    }
  };

  const updateText = async () => {
    try {
      if (error) {
        return;
      }
      setButtonLoading(true);
      const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      if (editorData.length > 8) {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/update`, {
          aboutUs: editorData,
          aboutUsTitle,
        });
        getAboutUs();
        toast(res.data.message);
        setButtonLoading(false);
      } else {
        setButtonLoading(false);
      }
    } catch (error) {
      console.log(error);
      setButtonLoading(false);
    }
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorData.length > 8) {
      setError(false);
    } else {
      setError(true);
    }
  }, [editorState]);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(`${contentText}`))));
  }, [contentText]);

  return (
    <>
      <Box sx={{ padding: 1 }}>
        {editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="About Us Title"
                value={aboutUsTitle}
                onChange={(e) => setAboutUsTitle(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  minHeight: '250px',
                  border: 'solid 1px #e6e6e6',
                  borderRadius: '10px',
                  justifyContent: 'unset',
                  alignItems: 'unset',
                  lineHeight: 'unset',
                  textAlign: 'unset',
                  fontSize: 'unset',
                  fontWeight: 'unset',
                  fontFamily: 'unset',
                  color: 'unset',
                }}
                padding={1}
              >
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button variant="outlined" size="small" onClick={removeFormatting}>
                    Remove Formatting
                  </Button>
                </Stack>
                <Editor
                  editorState={editorState}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName editor"
                  blockStyleFn={blockStyleFn}
                  onEditorStateChange={onEditorStateChange}
                  onBlur={() => setError(true)}
                  toolbar={{
                    options: ['inline', 'blockType', 'list', 'link', 'colorPicker', 'history'],
                    inline: {
                      inDropdown: false,
                      options: ['bold', 'italic', 'underline', 'strikethrough'],
                    },
                    blockType: {
                      inDropdown: true,
                      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                    },
                    list: {
                      inDropdown: false,
                      options: ['unordered', 'ordered'],
                    },
                    textAlign: {
                      inDropdown: undefined,
                      options: [],
                    },
                    link: {
                      inDropdown: false,
                      defaultTargetOption: '_blank',
                    },
                    colorPicker: {
                      inDropdown: false,
                    },
                    remove: { icon: undefined, className: 'toolbar-remove', component: undefined },
                    history: {
                      inDropdown: false,
                      options: ['undo', 'redo'],
                    },
                  }}
                />
              </Box>
              {error && (
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                  <Typography variant="caption" color="error">
                    Required
                  </Typography>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                <Button variant="outlined" onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
                <LoadingButton loading={buttonLoading} variant="contained" onClick={updateText}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        )}
        {!editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="end" spacing={1}>
                <Button size="small" variant="outlined" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom textAlign={'center'}>
                {aboutUsTitle}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ paddingX: '50px' }} className="textContent markdown">
                <Box ref={containerRef} padding={0} dangerouslySetInnerHTML={{ __html: htmlString }} />
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
}
