import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Card,
  Container,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import Page from '../components/Page';
import Iconify from '../components/Iconify';
import TextSectionMail from '../components/TextSectionMail';

function AddMail() {
  const navigate = useNavigate();
  const [richText, setRichText] = useState('');

  const mailSchema = Yup.object().shape({
    subject: Yup.string().required('Subject is required'),
    content: Yup.string().required('Content is required'),
  });

  const formik = useFormik({
    initialValues: {
      subject: '',
      isBlocked: 'true',
      content: richText,
    },
    validationSchema: mailSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/subscription/admin/mail`, {
          subject: values.subject,
          isBlocked: values.isBlocked,
          content: {
            contentType: 'RICH_TEXT',
            richTextContent: values.content,
          },
        });
        console.log(res, 'res');
        toast.success(res.data.message);
        navigate(-1);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    formik.setFieldValue('content', richText);
  }, [richText]);

  return (
    <Page title="Add Mail">
      <Container maxWidth="xl">
        <Card>
          <Stack direction={'row'} justifyContent="space-between" padding={1}>
            <Typography variant="h4"> Add Mail</Typography>
            <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" />
          </Stack>
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={9} lg={10}>
                    <TextField
                      fullWidth
                      label="Subject"
                      {...getFieldProps('subject')}
                      error={Boolean(touched.subject && errors.subject)}
                      helperText={touched.subject && errors.subject}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={2}>
                    <TextField fullWidth select label="Status" {...getFieldProps('isBlocked')}>
                      <MenuItem value="false">Active</MenuItem>
                      <MenuItem value="true">Blocked</MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <TextSectionMail setRichText={setRichText} />
                  </Grid>
                </Grid>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} marginTop={2}>
                  <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                    Add Mail
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}

export default AddMail;
