import { Add, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  Grid,
  TextField,
  IconButton,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { PieChart } from '../../sections/@dashboard/app';
import UpdateOeder from '../UpdateOeder';

export default function PieChartViewSection({ content, getFeed, feed, index, feedOwner }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [formData, setFormData] = useState(content.pieChart);
  const [title, setTitle] = useState(content.title);
  const [description, setDescription] = useState(content.description);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newData = [...formData];
    newData[index][name] = value;
    setFormData(newData);
  };

  const handleAdd = () => {
    setFormData([...formData, { name: '', value: '' }]);
  };

  const handleRemove = (index) => {
    const newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredData = formData.filter((item) => item.label !== '' && item.value !== '');
    if (!filteredData.length) return;
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content/pie-chart`, {
        id: feed._id,
        content: filteredData,
        contentId: content._id,
        title,
        description,
      });
      console.log('feed', res);
      toast(res.data.message);
      setEditMode(false);
      getFeed();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const deleteSection = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/feed/admin/content`, {
        data: {
          id: feed._id,
          contentId: content._id,
        },
      });
      console.log('delete text section', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };

  return (
    <>
      {/* delete popup  */}
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
          <Button onClick={deleteSection} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete popup  */}
      {!editMode && (
        <Card sx={{ padding: { xs: 0.5, sm: 1 } }}>
          <Grid item xs={12}>
            {feedOwner && (
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={1}>
                <UpdateOeder index={index} getFeed={getFeed} feed={feed} />
                <Stack spacing={1} direction={'row'}>
                  <LoadingButton
                    loading={buttonLoading}
                    size="small"
                    variant="outlined"
                    color="error"
                    onClick={handleDelete}
                  >
                    Delete
                  </LoadingButton>
                  <Button size="small" variant="outlined" onClick={() => setEditMode(true)}>
                    Edit
                  </Button>
                </Stack>
              </Stack>
            )}
          </Grid>
          <>
            <PieChart title={content.title} chartData={content.pieChart} subheader={content.description} />
          </>
        </Card>
      )}
      {editMode && (
        <Card sx={{ padding: { xs: 0.5, sm: 1 } }}>
          <Typography variant="h6" gutterBottom>
            Edit Pie Chart Data
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1} mb={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Title"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="title"
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  label="Description"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="description"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <Container maxWidth="md" sx={{ padding: 0 }}>
              {formData.map((item, index) => (
                <Grid
                  container
                  marginBottom={1}
                  spacing={{ xs: 1, sm: 2 }}
                  key={index}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={0.6} sm={0.4}>
                    <Typography>{index + 1}.</Typography>
                  </Grid>
                  <Grid item xs={7} sm={6.6}>
                    <TextField
                      label="Label"
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="label"
                      value={item.label}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={3.4} sm={4}>
                    <TextField
                      label="Value"
                      fullWidth
                      size="small"
                      variant="outlined"
                      name="value"
                      value={item.value}
                      onChange={(e) => handleChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <IconButton
                      onClick={() => handleRemove(index)}
                      sx={{
                        padding: { xs: 0 },
                        margin: { xs: 0 },
                      }}
                    >
                      <Delete sx={{ color: 'error.main' }} />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Stack direction="column" spacing={2} sx={{ marginTop: 2, width: '100%' }} alignItems="center">
                <Button
                  startIcon={<Add />}
                  variant="contained"
                  size="small"
                  onClick={handleAdd}
                  sx={{ width: '110px' }}
                  color="greenish"
                >
                  {formData.length ? 'Add More' : 'Start Adding'}
                </Button>
              </Stack>
            </Container>
            <Stack direction="row" spacing={2} sx={{ marginTop: 1, width: '100%' }} justifyContent="flex-end">
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  setEditMode(false);
                  setFormData(content.pieChart);
                }}
              >
                Cancel
              </Button>
              <Button size="small" variant="contained" type="submit" sx={{ width: '100px' }}>
                Submit
              </Button>
            </Stack>
          </form>
        </Card>
      )}
    </>
  );
}
