import moment from 'moment';
import axios from 'axios';
import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import {
  IconButton,
  TextField,
  Typography,
  Button,
  Stack,
  Box,
  MenuItem,
  Autocomplete,
  CircularProgress,
} from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [countries, setCountries] = React.useState([]);
  const [authors, setAuthors] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCountries = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all/names`);
      console.log('countries', res);
      const uncategorized = { _id: 'uncategorized', name: 'Uncategorized' };
      res.data.data.countries.unshift(uncategorized);
      setCountries(res.data.data.countries);
    } catch (error) {
      console.log(error);
    }
  };

  const getAuthors = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/author/all/filter`);
      console.log('authors', res);
      setAuthors(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCountries();
    getAuthors();
  }, []);

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                id: '',
                title: '',
                isBlocked: '',
                isPopular: '',
                startDate: '',
                endDate: '',
                countryId: '',
                authorId: '',
              }}
              onSubmit={async (values) => {
                const updateValues = {
                  ...values,
                  startDate: values.startDate ? new Date(moment(values.startDate).startOf('day')) : null,
                  endDate: values.endDate ? new Date(moment(values.endDate).endOf('day')) : null,
                };
                applyFilters(updateValues);
              }}
            >
              {({ values, errors, touched, handleChange, setFieldValue, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="title"
                        name="title"
                        label="Feed Title"
                        value={values.title}
                        fullWidth
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="id"
                        fullWidth
                        name="id"
                        label="Feed ID"
                        value={values.id}
                        onChange={handleChange}
                      />
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open5}
                        onOpen={() => {
                          setOpen5(true);
                        }}
                        onClose={() => {
                          setOpen5(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={authors}
                        onChange={(_, value) => {
                          if (value) {
                            setFieldValue('authorId', value._id);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Author"
                            InputProps={{
                              ...params.InputProps,
                            }}
                            error={Boolean(touched.authorId && errors.authorId)}
                            helperText={touched.authorId && errors.authorId}
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <Autocomplete
                        id="asynchronous-demo"
                        fullWidth
                        open={open1}
                        onOpen={() => {
                          setOpen1(true);
                        }}
                        onClose={() => {
                          setOpen1(false);
                        }}
                        isOptionEqualToValue={(option, value) => option.name === value.name}
                        getOptionLabel={(option) => option.name}
                        options={countries}
                        loading={loading}
                        onChange={(_, value) => setFieldValue('countryId', value._id)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // error={Boolean(touched.countryId && errors.countryId)}
                            // helperText={touched.countryId && errors.countryId}
                            label="Category"
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </>
                              ),
                            }}
                          />
                        )}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        select
                        fullWidth
                        id="isPopular"
                        name="isPopular"
                        label="Pinned"
                        value={values.isPopular}
                        onChange={handleChange}
                      >
                        <MenuItem value="true">Yes</MenuItem>
                        <MenuItem value="false">No</MenuItem>
                      </TextField>
                      <TextField
                        select
                        fullWidth
                        id="isBlocked"
                        name="isBlocked"
                        label="Status"
                        value={values.isBlocked}
                        onChange={handleChange}
                      >
                        <MenuItem value="true">Blocked</MenuItem>
                        <MenuItem value="false">Active</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
