import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import React from 'react';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

function EditPasswordPopup({ open, handleClose, type }) {
  const { id } = useParams();
  const passwordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .required(type === 'own' ? 'Current Password is required' : 'Superer Admin Password is required')
      .max(20)
      .min(8),
    newPassword: Yup.string().required('Password is required').max(20).min(8),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {},
    validationSchema: passwordSchema,
    onSubmit: async () => {
      try {
        if (type === 'own') {
          const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin/update/own/password`, values);
          toast(res.data.message);
          handleClose();
        } else {
          const res = await axios.put(`${process.env.REACT_APP_API_URL}/admin/update/admin/password`, {
            superAdminPassword: values.oldPassword,
            newPassword: values.newPassword,
            id,
          });
          toast(res.data.message);
          handleClose();
        }
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Change Password'}</DialogTitle>
      <DialogContent>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2} mt={0.5}>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label={type === 'own' ? 'Current Password' : 'Superer Admin Password'}
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('oldPassword')}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="New Password"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('newPassword')}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="password"
                  label="Confirm Password"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('confirmPassword')}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}

export default EditPasswordPopup;
