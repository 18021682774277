import { useState } from 'react';

// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography, Tab } from '@mui/material';
import { Box } from '@mui/system';
import { TabContext, TabList, TabPanel } from '@mui/lab';

import Page from '../components/Page';
import SettingsLinksTable from '../components/SettingsLinksTable';
import PrivacyPolicy from '../components/PrivacyPolicy';
import AboutUs from '../components/AboutUs';
import BackButton from '../components/BackButton';

export default function Settings() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Settings">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
          <Typography variant="h4" gutterBottom>
            Settings 
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ width: '100%' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Links" value="1" />
                  <Tab label="Privacy Policy" value="2" />
                  <Tab label="About" value="3" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <SettingsLinksTable />
              </TabPanel>
              <TabPanel value="2">
                <PrivacyPolicy />
              </TabPanel>
              <TabPanel value="3">
                <AboutUs />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
