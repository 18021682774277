import { useCallback, useEffect, useState } from 'react';
import { Box, Button, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

/// file pond //

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image to match crop

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform);

export default function VideoSection({ setSection, feed, getFeed }) {
  const [files, setFiles] = useState([]);

  const truevalue = true;
  const falsevalue = false;


  const formik = useFormik({
    initialValues: {
      id: feed._id,
      description: '',
    },
    onSubmit: async () => {
      const data = new FormData();
      data.append('id', values.id);
      data.append('description', values.description);
      files.forEach((item) => {
        data.append('video', item.file);
      });
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/feed/admin/content/video`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        getFeed();
        setSection('');
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;


  useEffect(() => {
    const video = [];
    files.forEach((item) => {
      video.push(item.file);
    });
    formik.setFieldValue('video', video);
  }, [files]);

  return (
    <Card sx={{ padding: 1 }}>
      <Typography variant="h4"> Add Video</Typography>
      <Box sx={{ paddingX: 1, paddingY: 2 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FilePond
                  files={files}
                  onupdatefiles={setFiles}
                  allowMultiple={falsevalue}
                  maxFiles={1}
                  credits={false}
                  allowImagePreview
                  // allowImageEdit
                  // server="/api"
                  allowImageCrop={truevalue}
                  allowImageTransform
                  name="files"
                  labelIdle="Drag & Drop Video File"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Title"
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                  <Button variant="contained" size="small" onClick={() => setSection('')}>
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" size="small" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
