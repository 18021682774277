import PropTypes from 'prop-types';
import moment from 'moment';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useState } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader, Stack, TextField, Typography } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// components
import { BaseOptionChart } from '../../../components/chart';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 398;
const LEGEND_HEIGHT = 116;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

AppCurrentVisits.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

const CssTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    fontSize: 13,
  },
  '& .MuiInputLabel-root': {
    fontSize: 13,
  },
  '& .MuiInputLabel-outlined': {
    transform: 'translate(14px, 10px) scale(1)',
  },
  '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#78909C',
    },
    '&:hover fieldset': {
      borderColor: '#78909C',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#78909C',
    },
  },
}); 

export default function AppCurrentVisits({ title, subheader, chartColors, getNewData, chartData, ...other }) {
  const theme = useTheme();
  const [startDate, setStartDate] = useState(moment().subtract(8, 'week').startOf('month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [options, setOptions] = useState({ startDate, endDate });

  const handleStartDateChange = (e) => {
    const temp = { ...options, startDate: new Date(moment(e.target.value).startOf('day')) };
    setStartDate(e.target.value);
    setOptions(temp);
    getNewData(temp);
  };
  const handleEndDateChange = (e) => {
    const temp = { ...options, endDate: new Date(moment(e.target.value).endOf('day')) };
    setEndDate(e.target.value);
    setOptions(temp);
    getNewData(temp);
  };

  const chartLabels = chartData.map((i) => i.label);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    dataLabels: { enabled: true, dropShadow: { enabled: false } },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {
          formatter: (seriesName) => {
            const name = seriesName.split(':');
            return `${name[0]}: `;
          },
        },
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other}>
      <Stack direction={'column'} alignItems="center" padding={1} sx={{ width: '100%' }}>
        <Typography marginBottom={1.5} marginTop={1} variant="h5">
          {title}
        </Typography>
        <Stack
          direction={'row'}
          paddingX={1}
          spacing={1}
          alignItems="center"
          sx={{ width: '100%' }}
          justifyContent="space-between"
        >
          <CssTextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500,
                width: '100px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            id="margin-none"
            value={startDate}
            type="date"
            onChange={handleStartDateChange}
          />
          <CssTextField
            sx={{
              '& .css-1j6hc4w-MuiInputBase-input-MuiOutlinedInput-input': {
                height: '22px',
                fontSize: '13px',
                fontWeight: 500,
                width: '100px',
                padding: '5.5px 4px',
              },
              '& .css-gpl0nr-MuiFormLabel-root-MuiInputLabel-root': {
                fontSize: '13px',
                top: 2,
              },
            }}
            label="End Date"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            value={endDate}
            type="date"
            onChange={handleEndDateChange}
          />
        </Stack>
      </Stack>

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={300} />
      </ChartWrapperStyle>
    </Card>
  );
}
