import * as React from 'react';
import moment from 'moment';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                feedId: '',
                isBlocked: '',
                userId: '',
                startDate: '',
                endDate: '',
                spamCount: '',
              }}
              onSubmit={async (values) => {
                const updateValues = {
                  ...values,
                  startDate: values.startDate ? new Date(moment(values.startDate).startOf('day')) : null,
                  endDate: values.endDate ? new Date(moment(values.endDate).endOf('day')) : null,
                };
                applyFilters(updateValues);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column" spacing={1} sx={{ width: '350px' }}>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="feedId"
                        name="feedId"
                        label="Feed Id"
                        value={values.feedId}
                        fullWidth
                        onChange={handleChange}
                      />
                      <TextField
                        id="spamCount"
                        name="spamCount"
                        label="Spam Count"
                        value={values.spamCount}
                        fullWidth
                        type={'number'}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        type="text"
                        fullWidth
                        id="userId"
                        name="userId"
                        label="User Id"
                        value={values.userId}
                        onChange={handleChange}
                      />
                      <TextField
                        select
                        fullWidth
                        id="isBlocked"
                        name="isBlocked"
                        label="Status"
                        value={values.isBlocked}
                        onChange={handleChange}
                      >
                        <MenuItem value="true">Blocked</MenuItem>
                        <MenuItem value="false">Active</MenuItem>
                      </TextField>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                      <TextField
                        id="startDate"
                        name="startDate"
                        label="Start Date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        value={values.startDate}
                        onChange={handleChange}
                      />
                      <TextField
                        id="endDate"
                        name="endDate"
                        label="End Date"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        value={values.endDate}
                        onChange={handleChange}
                      />
                    </Stack>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
