import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import AboutUsEditor from './AboutUsEditor';

function AboutUs() {
  const [aboutUs, setAboutUs] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAboutUs = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings/about-us`);
      console.log('privacy policy', res);
      setAboutUs(res.data.data || '');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAboutUs();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <AboutUsEditor data={aboutUs} getAboutUs={getAboutUs} />
        </div>
      )}
    </>
  );
}

export default AboutUs;
