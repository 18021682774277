import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  IconButton,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { Delete, Edit } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AddIcon from '@mui/icons-material/Add';
import ModeEditSharpIcon from '@mui/icons-material/ModeEditSharp';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Promotions';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/promotionStatusChange';
import Popup1 from '../components/popups/promotionStatusDelete';
import PromotionPriorityUpdatePopup from '../components/popups/PromotionPriorityUpdatePopup';
import BackButton from '../components/BackButton';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Promotions() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [promotionData, setCountryData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [promotionCount, setCountryCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [priorityUpdateIndex, setPriorityUpdateIndex] = useState();
  const [promotionTitle, setPromotionTitle] = useState('Promotions');
  const [newTitle, setNewTitle] = useState('');
  const [titleEditMode, setTitleEditMode] = useState(false);

  const promotionSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    link: Yup.string().required('Is popular is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      isBlocked: 'false',
      link: '',
    },
    validationSchema: promotionSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/promotion/admin`, values);
        if (!res.data.isError) {
          closeAddUserModal(res.data.message);
          toast.success(res.data.message);
        } else {
          toast.error(res.data.message);
        }
        getPromotions(options);
        resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };
  const handleDelete = (event, item, index) => {
    setSelectedIndex1(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    setPriorityUpdateIndex();
    if (refresh) {
      getPromotions(options);
    }
    if (message) {
      toast(message);
    }
  };
  const openAddUserModal = () => {
    setOpen(true);
  };
  const closeAddUserModal = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getPromotions(temp);
  };

  const getPromotions = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/promotion/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setCountryCount(res.data.data.maxRecords);
          setCountryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getPromotionTitle = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/settings/promotion`)
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setPromotionTitle(res?.data?.data?.promotionTitle);
          setNewTitle(res?.data?.data?.promotionTitle);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmitNewTitle = async () => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/update`, {
        promotionTitle: newTitle,
      });
      toast(res.data.message);
      setTitleEditMode(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const getPromotionsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/promotion/admin/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setCountryCount(res.data.data.maxRecords);
          setCountryData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(promotionCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getPromotions(temp);
  };

  const handlePriorityChange = (index) => {
    setPriorityUpdateIndex(index);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, title: e.target.value };
    setOptions(temp);
    setPages(0);
    getPromotionsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }

    setOptions(temp);
    setPages(0);
    getPromotions(temp);
  };
  useEffect(() => {
    getPromotions(options);
    getPromotionTitle();
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getPromotions(temp);
  }, [size]);

  return (
    <>
      <Page title="Promotions">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div> 
        ) : (
          <>
            {/* Add promotion Dialog */}
            <Dialog open={open} onClose={closeAddUserModal}>
              <DialogTitle>Add Promotion</DialogTitle>
              <Grid container paddingBottom={'10px'} Width="500px">
                <DialogContent paddingTop="20px">
                  <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack direction={'column'} spacing={1} width="500px">
                          <Stack direction="row" justifyContent="space-between">
                            <TextField
                              type="text"
                              label="Title"
                              fullWidth
                              value={values.title}
                              {...getFieldProps('title')}
                              error={Boolean(touched.title && errors.title)}
                              helperText={touched.title && errors.title}
                            />
                          </Stack>
                          <Stack direction="row" justifyContent="space-between" spacing={1}>
                            <TextField
                              type="text"
                              label="Link"
                              fullWidth
                              value={values.link}
                              {...getFieldProps('link')}
                              error={Boolean(touched.link && errors.link)}
                              helperText={touched.link && errors.link}
                            />
                          </Stack>
                        </Stack>
                      </Grid>
                      <Stack direction={'row'} justifyContent="flex-end" paddingTop="20px">
                        <Button onClick={closeAddUserModal}>Cancel</Button>
                        <LoadingButton type="submit" loading={isSubmitting}>
                          Add Promotion
                        </LoadingButton>
                      </Stack>
                    </Form>
                  </FormikProvider>
                </DialogContent>
              </Grid>
            </Dialog>
            {/* Add promotion dialog end */}
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                {!titleEditMode && (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Typography variant="h4" gutterBottom>
                      {promotionTitle}
                    </Typography>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => setTitleEditMode(true)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                )}
                {titleEditMode && (
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <TextField
                      type="text"
                      size="small"
                      label="Title"
                      fullWidth
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                    <Button size="small" onClick={handleSubmitNewTitle} variant="contained" color="primary">
                      Save
                    </Button>
                    <Button size="small" onClick={() => setTitleEditMode(false)} variant="outlined" color="error">
                      Cancel
                    </Button>
                  </Stack>
                )}
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  {/* <Stack direction="row" spacing={2} padding={2}>
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
                  <Stack direction="row" spacing={2} padding={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search promotion..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  {isSuperAdmin && (
                    <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                      <Button startIcon={<AddIcon />} variant="contained" onClick={openAddUserModal}>
                        Add Promotion
                      </Button>

                      <Filter applyFilters={applyFilters} />
                    </Stack>
                  )}
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          {/* <TableCell>Promotion ID</TableCell> */}
                          <TableCell>Title</TableCell>
                          <TableCell>Link</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>Status</TableCell>
                          {isSuperAdmin && <TableCell>Actions</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {promotionData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                            <TableCell>{pages * size + (id + 1)}</TableCell>
                            <TableCell>{item?.title}</TableCell>
                            <TableCell style={{ cursor: 'pointer' }}>{item?.link}</TableCell>
                            {isSuperAdmin ? (
                              <TableCell>
                                <Tooltip title=" Click to Change Priority">
                                  <Typography sx={{ cursor: 'pointer' }} onClick={() => handlePriorityChange(id)}>
                                    {item.priority || 'Not Set'}
                                  </Typography>
                                </Tooltip>
                                {priorityUpdateIndex === id && (
                                  <PromotionPriorityUpdatePopup item={item} handleClose={handleClose} />
                                )}
                              </TableCell>
                            ) : (
                              <TableCell>{item.priority || 'Not Set'}</TableCell>
                            )}
                            {isSuperAdmin ? (
                              <TableCell>
                                <FormControl sx={{ minWidth: 100 }}>
                                  <Select
                                    size="small"
                                    value={item.isBlocked}
                                    onChange={(e) => handleStatusChange(e, item, id)}
                                  >
                                    <MenuItem value="false">Active</MenuItem>
                                    <MenuItem value="true">Blocked</MenuItem>
                                  </Select>
                                  {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                                </FormControl>
                              </TableCell>
                            ) : (
                              <TableCell>{item.isBlocked ? 'Blocked' : 'Active'}</TableCell>
                            )}
                            {isSuperAdmin && (
                              <TableCell>
                                <Box>
                                  <IconButton onClick={(e) => handleDelete(e, item, id)}>
                                    <Delete />
                                  </IconButton>
                                </Box>
                                {selectedIndex1 === id && <Popup1 item={item} handleClose={handleClose} />}
                              </TableCell>
                            )}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={promotionCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
