import {
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ArrowBack, Close } from '@mui/icons-material';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import Popup from '../components/popups/MessageStatusChange';

function MessageView() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [status, setStatus] = useState('');
  const [selectedIndex, setSelectedIndex] = useState();

  const getMessage = () => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/contact-us/one`, {
        params: {
          id,
        },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMessage(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const handleStatusChange = (event, item) => {
    if (item.status === event.target.value) {
      return;
    }
    setSelectedIndex(item._id);
    setStatus(event.target.value);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getMessage();
    }
    if (message) {
      toast(message);
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  return (
    <>
      <Page title="Contact Us">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h4" gutterBottom>
                  Message Details
                </Typography>
                <IconButton onClick={() => navigate(-1)}>
                  <ArrowBack />
                </IconButton>
              </Stack>
              <Card>
                <CardContent>
                  <Stack direction="row" justifyContent="space-between" mb={2}>
                    <Stack direction="column">
                      <Typography variant="h6" sx={{ lineHeight: '15px' }} gutterBottom>
                        {message?.name}
                      </Typography>
                      <Typography variant="body1" sx={{ lineHeight: '15px' }} gutterBottom>
                        {message?.email}
                      </Typography>
                      <Typography variant="caption" sx={{ lineHeight: '15px' }} gutterBottom>
                        {moment(message?.createdAt).format('DD/MM/YYYY hh:mm A')}
                      </Typography>
                    </Stack>
                    <FormControl sx={{ minWidth: 100 }}>
                      <Select size="small" value={message.status} onChange={(e) => handleStatusChange(e, message)}>
                        <MenuItem value="PENDING">PENDING</MenuItem>
                        <MenuItem value="REVIEWED">REVIEWED</MenuItem>
                      </Select>
                      {selectedIndex && <Popup item={message} status={status} handleClose={handleClose} />}
                    </FormControl>
                  </Stack>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      width: '100%',
                      whiteSpace: 'pre-wrap',
                      wordWrap: 'break-all',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {message?.message}
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}

export default MessageView;
