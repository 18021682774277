import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';

const PopupReply = (props) => {
  const [reply, setReply] = React.useState('');
  const deleteFeed = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/comment/reply/admin`, {
        commentId: props.item._id,
        feedId: props.item.feedId,
        comment: reply,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false, 
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={() => props.handleClose(false)} open={open}>
        <DialogTitle>Reply</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              sx={{ minWidth: '350px' }}
              value={reply}
              onChange={(e) => setReply(e.target.value)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose(false)}>Cancel</Button>
          <Button onClick={deleteFeed}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupReply;
