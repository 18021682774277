import {
  Button,
  Container,
  Card,
  Grid,
  Box,
  Stack,
  Typography,
  TextField,
  MenuItem,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { BorderColor, Edit } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import '../style.css';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useNavigate, useParams } from 'react-router-dom';
import Recipients from '../components/Recipients';
import Page from '../components/Page';
import Iconify from '../components/Iconify';

function ViewMail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editMode, setEditMode] = useState(false);
  const [editMode1, setEditMode1] = useState(false);
  const [error, setError] = useState(false);
  const [htmlString, setHtmlString] = useState('');
  const [contentText, setContentText] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [email, setEmail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [callAPI, setCallAPI] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const blockStyleFn = (contentBlock) => {
    const type = contentBlock.getType();
    if (type === 'unstyled') {
      return 'indent';
    }
  };

  const getEmail = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/subscription/admin/mail`, {
        params: {
          id,
        },
      });
      console.log(res, 'res');
      setEmail(res.data.data);
      if (res.data.data && res.data.data.content && res.data.data.content.length > 0) {
        setContentText(res.data.data.content[0].richTextContent);
        setHtmlString(res.data.data.content[0].richTextContent);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const updateText = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/subscription/admin/mail/content/rich-text`, {
        richTextContent: draftToHtml(convertToRaw(editorState.getCurrentContent())),
        mailId: id,
        contentId: email.content[0]._id,
      });
      console.log(res, 'res');
      toast.success(res.data.message);
      setEditMode(false);
      setButtonLoading(false);
      getEmail();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const updateEmailData = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/subscription/admin/mail`, {
        subject: email.subject,
        isBlocked: email.isBlocked,
        id,
      });
      console.log(res, 'res');
      toast.success(res.data.message);
      setEditMode1(false);
      setButtonLoading(false);
      getEmail();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const sendMail = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/subscription/admin/mail/add-all-subscribers`, {
        id,
      });
      console.log(res, 'res');
      toast.success(res.data.message);
      setButtonLoading(false);
      setCallAPI(!callAPI);
      getEmail();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorData.length > 8) {
      setError(false);
    } else {
      setError(true);
    }
  }, [editorState]);

  useEffect(() => {
    setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(`${contentText}`))));
  }, [contentText]);

  useEffect(() => {
    getEmail();
  }, []);

  return (
    <Page title="View Mail">
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack direction={'row'} justifyContent="space-between" padding={1} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'} spacing={2} padding={1}>
              <Typography variant="h4">View Mail</Typography>
              {!email.isBlocked && (
                <LoadingButton variant="contained" onClick={sendMail}>
                  Send Mail
                </LoadingButton>
              )}
            </Stack>
            <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" />
          </Stack>
          <Card>
            {' '}
            <Stack spacing={2} padding={2}>
              <Box mb={5}>
                <Stack direction="row" alignItems="center" mb={2}>
                  <Typography variant="h6">Email Details</Typography>
                  {!editMode1 && (
                    <IconButton onClick={() => setEditMode1(true)}>
                      <BorderColor />
                    </IconButton>
                  )}
                </Stack>
                <Grid container spacing={2} py={1}>
                  <Grid item xs={12} md={9} lg={10}>
                    <TextField
                      fullWidth
                      label="Subject"
                      variant="outlined"
                      size="small"
                      value={email.subject}
                      disabled={!editMode1}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(e) => setEmail({ ...email, subject: e.target.value })}
                    />
                  </Grid>
                  <Grid item xs={12} md={3} lg={2}>
                    <TextField
                      fullWidth
                      label="Status"
                      select
                      variant="outlined"
                      size="small"
                      value={email.isBlocked}
                      disabled={!editMode1}
                      onChange={(e) => setEmail({ ...email, isBlocked: e.target.value })}
                    >
                      <MenuItem value="false">Active</MenuItem>
                      <MenuItem value="true">Blocked</MenuItem>
                    </TextField>
                  </Grid>
                  {editMode1 && (
                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                        <Button variant="outlined" onClick={() => setEditMode1(false)}>
                          Cancel
                        </Button>
                        <LoadingButton loading={buttonLoading} variant="contained" onClick={updateEmailData}>
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box mb={5}>
                <Stack direction="row" alignItems="center" mb={2}> 
                  <Typography variant="h6">Email</Typography>
                  {!editMode && (
                    <IconButton size="small" variant="outlined" onClick={() => setEditMode(true)}>
                      <BorderColor />
                    </IconButton>
                  )}
                </Stack>
                <Box sx={{ padding: 1 }}>
                  {!editMode && (
                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={12}>
                        <Box
                          sx={{ paddingX: '50px' }}
                          // className="textContent markdown"
                        >
                          <Box padding={0} dangerouslySetInnerHTML={{ __html: htmlString }} />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={2}>
                    {editMode && (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            minHeight: '250px',
                            border: 'solid 1px #e6e6e6',
                            borderRadius: '10px',
                            justifyContent: 'unset',
                            alignItems: 'unset',
                            lineHeight: 'unset',
                            textAlign: 'unset',
                            fontSize: 'unset',
                            fontWeight: 'unset',
                            fontFamily: 'unset',
                            color: 'unset',
                            marginX: '50px',
                          }}
                          padding={1}
                        >
                          <Editor
                            // eslint-disable-next-line react/jsx-no-bind
                            // keyBindingFn={myKeyBindingFn}
                            // handleKeyCommand={handleKeyCommand}
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName editor"
                            blockStyleFn={blockStyleFn}
                            onEditorStateChange={onEditorStateChange}
                            onBlur={() => setError(true)}
                            toolbar={{
                              options: ['inline', 'blockType', 'list', 'link', 'colorPicker', 'history'],
                              inline: {
                                inDropdown: false,
                                options: ['bold', 'italic', 'underline', 'strikethrough'],
                              },
                              blockType: {
                                inDropdown: true,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                              },
                              list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                              },
                              textAlign: {
                                inDropdown: undefined,
                                options: [],
                              },
                              link: {
                                inDropdown: false,
                                defaultTargetOption: '_blank',
                              },
                              colorPicker: {
                                inDropdown: false,
                              },
                              remove: { icon: undefined, className: 'toolbar-remove', component: undefined },
                              history: {
                                inDropdown: false,
                                options: ['undo', 'redo'],
                              },
                            }}
                          />
                        </Box>
                        {error && (
                          <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                            <Typography variant="caption" color="error">
                              Required
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                    )}
                    {editMode && (
                      <Grid item xs={12}>
                        <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                          <Button variant="outlined" onClick={() => setEditMode(false)}>
                            Cancel
                          </Button>
                          <LoadingButton loading={buttonLoading} variant="contained" onClick={updateText}>
                            Submit
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
              <div>
                <Box mt={2}>
                  <Typography variant="h6">Recipients</Typography>
                </Box>
                <Recipients id={id} callAPI={callAPI} />
              </div>
            </Stack>
          </Card>
        </Container>
      )}
    </Page>
  );
}

export default ViewMail;
