import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const PopupDelete = ({id, handleClose}) => {
  const navigate = useNavigate();
  const deleteFeed = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/author`, {
        params: {
          id,
        },
      })
      .then((res) => {
        toast(res.data.message);
        navigate('/dashboard/author');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={() => handleClose()} open={open}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Author?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>Cancel</Button>
          <Button onClick={deleteFeed}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupDelete;
