import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Stack,
  Button,
  CircularProgress,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TablePagination,
  TextField,
  Box,
  Avatar,
  Chip,
} from '@mui/material';
import { ContentCopy, Visibility } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Popup from '../components/popups/CommentStatus';
import Filter from '../components/filters/Comments';
import PopupReply from '../components/popups/CommentReply';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Comments() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const adminId = accountData._id;
  const navigate = useNavigate();
  const [commentData, setCommentData] = useState();
  const [pages, setPages] = useState(0);
  const [commentCount, setCommentCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0, isReply: false });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const getCommentPosts = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/comment/admin/all`, {
        params: options,
      });
      console.log('comment posts', res);
      setCommentData(res.data.data.comments);
      setCommentCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };


 

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setOpen(false);
    setOpen1(false);
    if (refresh) {
      getCommentPosts(options);
      toast(message);
    } else if (message) {
      toast.error(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getCommentPosts(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(commentCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getCommentPosts(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size, isReply: false };
    if (filter.feedId.length) {
      temp.feedId = filter.feedId;
    }
    if (filter.userId.length) {
      temp.userId = filter.userId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.isReply.length) {
      temp.isReply = filter.isReply;
    }
    if (filter.spamCount) {
      temp.spamCount = filter.spamCount;
    }
    setOptions(temp);
    setPages(0);
    getCommentPosts(temp);
  };

  useEffect(() => {
    getCommentPosts(options);
  }, []);

  return (
    <Page title="Comments">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography variant="h4">Comments</Typography>
          </Stack>
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} padding={2}>
                <TextField
                  size="small"
                  style={{ maxWidth: '100px', maxHeight: '35px' }}
                  type="number"
                  component="div"
                  label="Go to"
                  value={input}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      goToPage();
                    }
                  }}
                  onInput={(e) => setInput(e.target.value)}
                />
                <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                  Go
                </Button>
              </Stack>
              <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                <Filter applyFilters={applyFilters} />
              </Stack>
            </Stack>

            <Scrollbar>
              {commentData?.map((item, id) => (
                <Stack
                  direction="column"
                  key={id}
                  padding={2}
                  sx={{
                    boxShadow: 'rgba(0, 0, 0, 0.203) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                    minWidth: '800px',
                    borderRadius: '10px',
                    marginBottom: '10px',
                    backgroundColor: 'white',
                  }}
                  margin={2}
                >
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography varaint="caption" color="text.secondary">
                        {Moment(item.createdAt).format('DD/MM/YYYY')}
                      </Typography>
                      {!item.adminName ? (
                        <Chip
                          size="small"
                          sx={{ backgroundColor: item.accType === 'AUTHENTICATED' ? '#10ba68' : '#ad2742' }}
                          color="primary"
                          label={item.accType}
                        />
                      ) : (
                        <Chip size="small" color="primary" label={'ADMIN'} />
                      )}
                    </Stack>
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                      <CopyToClipboard text={item.feedId} onCopy={() => toast.info('Feed ID copied')}>
                        <Button startIcon={<ContentCopy sx={{ fontSize: '20px' }} />} color="brownee">
                          Feed ID
                        </Button>
                      </CopyToClipboard>
                      <Stack direction="row" alignItems="center">
                        <Iconify
                          icon="ri:spam-2-fill"
                          sx={{
                            color: '#ff0000',
                            fontSize: '24px',
                          }}
                        />
                        <Typography variant="p" color="text.secondary" mr={0.5}>
                          Spam:
                        </Typography>
                        <Typography variant="p">{item.spam}</Typography>
                      </Stack>
                      {isSuperAdmin || item.adminId === adminId || item?.feedAuthorAdminId === adminId ? (
                        <>
                          <FormControl sx={{ minWidth: 100 }}>
                            <Select
                              size="small"
                              value={item.isBlocked}
                              sx={{
                                height: '25px',
                                boxShadow: 'none',
                              }}
                              onChange={(e) => handleStatusChange(e, item, id)}
                            >
                              <MenuItem value="false">Active</MenuItem>
                              <MenuItem value="true">Blocked</MenuItem>
                            </Select>
                            {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                          </FormControl>
                        </>
                      ) : (
                        <Stack direction="row" alignItems="center">
                          <Chip size="small" label={item.isBlocked ? 'Blocked' : 'Active'} />
                        </Stack>
                      )}

                      {open1 && <PopupReply item={item} handleClose={handleClose} />}
                      {/* {isSuperAdmin && (
                      <Button
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteForever />}
                        onClick={deleteAuthorPopup}
                      >
                        Delete
                      </Button>
                    )}
                    {open && <PopupDelete item={item} handleClose={handleClose} />} */}
                      <Button
                        startIcon={<Visibility />}
                        onClick={() =>
                          navigate(
                            item.adminName
                              ? `/dashboard/comment/view/${item.commentRefId}`
                              : `/dashboard/comment/view/${item._id}`
                          )
                        }
                        variant="contained"
                        color="greenish"
                        sx={{
                          height: '25px',
                          boxShadow: 'none',
                        }}
                        size="small"
                      >
                        Comment
                      </Button>
                      {/* <Button
                        variant="contained"
                        sx={{
                          height: '25px',
                          boxShadow: 'none',
                        }}
                        onClick={replyPopup}
                        startIcon={<Reply />}
                      >
                        Reply
                      </Button> */}
                      {/* <Button
                        color="greenish"
                        variant="contained"
                        sx={{
                          height: '25px',
                          boxShadow: 'none',
                        }}
                        startIcon={<Visibility />}
                        onClick={() => navigate(`/dashboard/feed/view/${item.feedId}`)}
                      >
                        Feed
                      </Button> */}
                    </Stack>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar
                        sx={{
                          width: '50px',
                          height: '50px',
                        }}
                        src={item.authorName ? item?.authorProfilePic?.fileUrl : item.profilePic}
                      />
                      {!item.authorName ? (
                        <Stack direction="column">
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            {item.accType === 'AUTHENTICATED' ? item.username : item?.metadata?.fullname}
                          </Typography>
                          <Typography variant="p" color="text.secondary">
                            {item.accType === 'AUTHENTICATED' ? item.email : item?.metadata?.email}
                          </Typography>
                        </Stack>
                      ) : (
                        <Stack direction="column">
                          <Typography
                            variant="p"
                            sx={{
                              fontWeight: 'bold',
                            }}
                          >
                            {item.authorName}
                          </Typography>
                          {/* <Typography variant="p" color="text.secondary">
                        {item.adminEmail}
                      </Typography> */}
                        </Stack>
                      )}
                    </Stack>
                  </Stack>

                  <Box
                    sx={{
                      padding: '5px',
                      borderRadius: '5px',
                      marginLeft: '60px',
                      marginY: '10px',
                    }}
                  >
                    <Typography variant="p" mt={1}>
                      {item.comment}
                    </Typography>
                  </Box>
                </Stack>
              ))}
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[]}
              component={'div'}
              showFirstButton
              showLastButton
              count={commentCount}
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </>
        </Container>
      )}
    </Page>
  );
}
