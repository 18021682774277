import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
  Grid,
  TextField,
  MenuItem,
  IconButton,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { BarAndLineGraph } from '../../sections/@dashboard/app';
import UpdateOeder from '../UpdateOeder';

export default function BarGraphViewSection({ content, getFeed, feed, index, feedOwner }) {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [labels, setLabel] = useState(content.barGraph.label);
  const [chartData, setChartData] = useState(content.barGraph.chartData);
  const [formError, setFormError] = useState(false);
  const [title, setTitle] = useState(content.title);
  const [description, setDescription] = useState(content.description);

  const handleAddLabel = () => {
    setLabel([...labels, '']);
    const newData = [...chartData];
    newData.forEach((item) => {
      item.data.push('');
    });
  };

  const handleRemoveLabel = (index) => {
    const newData = [...labels];
    newData.splice(index, 1);
    setLabel(newData);
    chartData.forEach((item) => {
      item.data.splice(index, 1);
    });
  };

  const handleChangeLabel = (e, index) => {
    const { value } = e.target;
    const newData = [...labels];
    newData[index] = value;
    setLabel(newData);
  };

  const handleAddChart = () => {
    setChartData([
      ...chartData,
      {
        name: '',
        data: labels.map(() => ''),
        chartType: '',
        fill: '',
      },
    ]);
  };

  const handleRemoveChart = (index) => {
    const newData = [...chartData];
    newData.splice(index, 1);
    setChartData(newData);
  };

  const handleChangeChartNameChartTypeOrFill = (e, index) => {
    const { name, value } = e.target;
    const newData = [...chartData];
    newData[index][name] = value;
    setChartData(newData);
  };

  const handleChangeChartDataData = (e, index, dataIndex) => {
    const { value } = e.target;
    const newData = [...chartData];
    newData[index].data[dataIndex] = value;
    setChartData(newData);
  };

  const handleSubmit = async () => {
    const error = chartData.some(
      (item) => !item.name || !item.chartType || !item.fill || item.data.some((data) => !data)
    );
    const emptyLabel = labels.some((item) => !item.length);
    if (error || emptyLabel) {
      setFormError(true);
      toast.error('Please fill all the fields or remove the empty fields');
      return;
    }
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content/bar-graph`, {
        label: labels,
        chartData,
        id: feed._id,
        contentId: content._id,
        title,
        description,
      });
      console.log(res);
      toast(res.data.message);
      setEditMode(false);
      getFeed();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const deleteSection = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/feed/admin/content`, {
        data: {
          id: feed._id,
          contentId: content._id,
        },
      });
      console.log('delete text section', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };

  return (
    <>
      {/* delete popup  */}
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
          <Button onClick={deleteSection} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete popup  */}
      {!editMode && (
        <Card sx={{ padding: { xs: 0.5, sm: 1 } }}>
          {feedOwner && (
            <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={1}>
              <UpdateOeder index={index} getFeed={getFeed} feed={feed} />
              <Stack spacing={1} direction={'row'}>
                <LoadingButton
                  loading={buttonLoading}
                  size="small"
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                >
                  Delete
                </LoadingButton>
                <Button size="small" variant="outlined" onClick={() => setEditMode(true)}>
                  Edit
                </Button>
              </Stack>
            </Stack>
          )}
          <BarAndLineGraph
            title={content.title}
            chartData={content.barGraph.chartData}
            chartLabels={content.barGraph.label}
            subheader={content.description}
          />
        </Card>
      )}
      {editMode && (
        <Card sx={{ padding: 1 }}>
          <Typography variant="h5" gutterBottom>
            Edit Bar Graph Data
          </Typography>
          <Card sx={{ padding: 1 }}>
            <Grid container spacing={1} mb={2}>
              <Grid item xs={12}>
                <TextField
                  label="Title"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  fullWidth
                  size="small"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>

            <Typography variant="h5" gutterBottom>
              Labels
            </Typography>
            <Grid container spacing={1} direction="column" alignItems={'center'}>
              {labels.map((item, index) => (
                <Grid item xs={12} sm={10} md={8} lg={7} xl={6} key={index}>
                  <Stack direction="row" spacing={1} alignItems="center" sx={{ width: '100%' }}>
                    <Typography>{index + 1}.</Typography>
                    <TextField
                      label="Label"
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={item}
                      autoFocus={index === labels.length - 1}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleAddLabel();
                        }
                      }}
                      onChange={(e) => handleChangeLabel(e, index)}
                      InputProps={{
                        endAdornment: (
                          <Delete color="error" sx={{ cursor: 'pointer' }} onClick={() => handleRemoveLabel(index)} />
                        ),
                      }}
                      helperText={formError && !item.length && 'Required'}
                      error={formError && !item.length}
                    />
                  </Stack>
                </Grid>
              ))}
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="center" mb={2} sx={{ width: '100%' }}>
                  <Button variant="contained" onClick={handleAddLabel} size="small">
                    {labels.length ? 'Add More' : 'Start Adding Labels'}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Card>
          {!!labels.length && (
            <Card sx={{ padding: 1, mt: 1 }}>
              <Typography variant="h5">Charts</Typography>
              <Grid container spacing={3} direction="column" alignItems={'center'}>
                {chartData.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    key={index}
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '5px',
                      paddingX: '10px',
                      paddingY: '20px',
                      marginTop: '10px',
                      boxShadow: '0 0 5px #ccc',
                    }}
                  >
                    <Stack direction="row" spacing={1} mb={2} sx={{ flexWrap: 'wrap' }} alignItems="center">
                      <Typography>{index + 1}.</Typography>
                      <TextField
                        label="Chart Name"
                        size="small"
                        variant="outlined"
                        name="name"
                        sx={{ width: '250px' }}
                        value={item.name}
                        onChange={(e) => handleChangeChartNameChartTypeOrFill(e, index)}
                        error={formError && !item.name}
                        helperText={formError && !item.name && 'Required'}
                      />
                      <TextField
                        label="Chart Type"
                        select
                        size="small"
                        variant="outlined"
                        name="chartType"
                        sx={{ width: '150px' }}
                        value={item.chartType}
                        onChange={(e) => handleChangeChartNameChartTypeOrFill(e, index)}
                        error={formError && !item.chartType}
                        helperText={formError && !item.chartType && 'Required'}
                      >
                        <MenuItem value="column">Bar</MenuItem>
                        <MenuItem value="line">Line</MenuItem>
                        <MenuItem value="area">Area</MenuItem>
                      </TextField>
                      <TextField
                        label="Fill"
                        select
                        size="small"
                        variant="outlined"
                        name="fill"
                        value={item.fill}
                        sx={{ width: '100px' }}
                        onChange={(e) => handleChangeChartNameChartTypeOrFill(e, index)}
                        helperText={formError && !item.fill && 'Required'}
                        error={formError && !item.fill}
                      >
                        <MenuItem value="solid">Solid</MenuItem>
                        <MenuItem value="gradient">Gradient</MenuItem>
                      </TextField>

                      <IconButton variant="contained" color="error" onClick={() => handleRemoveChart(index)}>
                        <Delete />
                      </IconButton>
                    </Stack>
                    <Container maxWidth="sm" spacing={1}>
                      <Stack direction="row" spacing={1} alignItems="center" mb={1}>
                        <Typography variant="h6">Data</Typography>
                      </Stack>

                      <Stack spacing={1}>
                        {item.data.map((dataItem, dataIndex) => (
                          <Stack key={dataIndex} direction="row" spacing={1} alignItems="center">
                            <TextField
                              sx={{ width: '50%' }}
                              size="small"
                              label="Label"
                              value={labels[dataIndex]}
                              disabled
                            />
                            <TextField
                              label="Data"
                              type="number"
                              fullWidth
                              size="small"
                              variant="outlined"
                              value={dataItem}
                              // on enter press go to next input
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  document.getElementById(`data-${index}-${dataIndex + 1}`).focus();
                                }
                              }}
                              id={`data-${index}-${dataIndex}`}
                              onChange={(e) => handleChangeChartDataData(e, index, dataIndex)}
                              sx={{ width: '50%' }}
                              // error={!dataItem}
                              // helperText={!dataItem && 'Data is required'}
                              helperText={formError && !dataItem && 'Required'}
                              error={formError && !dataItem}
                            />
                            {/* <Button variant="contained" onClick={() => handleRemoveChartDataData(index, dataIndex)}>
                          Remove Data
                        </Button> */}
                          </Stack>
                        ))}
                      </Stack>
                    </Container>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Button variant="contained" onClick={handleAddChart} size="small">
                    Add Chart
                  </Button>
                </Grid>
              </Grid>
              <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditMode(false);
                  }}
                  size="small"
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" onClick={handleSubmit}>
                  Submit
                </Button>
              </Stack>
            </Card>
          )}
          {!labels.length && (
            <Stack direction="row" spacing={1} justifyContent="flex-end" mt={2}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEditMode(false);
                }}
              >
                Cancel
              </Button>
            </Stack>
          )}
        </Card>
      )}
    </>
  );
}
