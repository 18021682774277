import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  OutlinedInput,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { Box } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { useFormik, Form, FormikProvider } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Filter from './filters/Recipients';

// components
import Page from './Page';
import Scrollbar from './Scrollbar';
import Iconify from './Iconify';
// import Popup from './popups/recipientStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Recipients({ id, callAPI }) {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [recipientData, setRecipientData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [recipientCount, setRecipientCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getRecipients(temp);
  };

  const getRecipients = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/subscription/admin/mail/recipients`, {
        params: { ...options, id },
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setRecipientCount(res.data.data.maxRecords);
          setRecipientData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getRecipientsWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/subscription/admin/recipient/all`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setRecipientCount(res.data.data.maxRecords);
          setRecipientData(res.data.data.records);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getRecipientsWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.isBlocked.length) {
      temp.isBlocked = filter.isBlocked.trim();
    }
    setOptions(temp);
    setPages(0); 
    getRecipients(temp);
  };
  useEffect(() => {
    getRecipients(options);
  }, [callAPI]);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getRecipients(temp);
  }, [size]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Box>
          <Container maxWidth="xl">
            <Stack direction="row" justifyContent="end" alignItems="center">
              {/* <Stack direction="row" spacing={2} padding={2}>
                <SearchStyle
                  value={name}
                  onChange={onSearch}
                  placeholder="Search recipient..."
                  startAdornment={
                    <InputAdornment position="start">
                      <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                    </InputAdornment>
                  }
                />
              </Stack> */}
              {isSuperAdmin && (
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  {/* <Filter applyFilters={applyFilters} /> */}
                </Stack>
              )}
            </Stack>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sl No</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {recipientData?.map((item, id) => (
                      <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                        <TableCell>{pages * size + (id + 1)}</TableCell>

                        <TableCell>{item.email}</TableCell>
                        <TableCell>{item?.send ? 'Sent' : 'Not Sent'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              onRowsPerPageChange={(e) => {
                setSize(e.target.value);
              }}
              component={'div'}
              count={recipientCount}
              showFirstButton
              showLastButton
              onPageChange={handlePageChange}
              rowsPerPage={size}
              page={pages}
            />
          </Container>
        </Box>
      )}
    </>
  );
}
