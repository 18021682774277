import { Button, Container, Card, Grid, Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

/// editor //
import { Editor } from 'react-draft-wysiwyg';
import {
  ContentState,
  convertToRaw,
  EditorState,
  getDefaultKeyBinding,
  KeyBindingUtil,
  RichUtils,
  Modifier,
  SelectionState,
  CharacterMetadata,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';

export default function TextSectionMail({ setRichText }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const removeInlineStyles = (editorState, retainInlineStyles = []) => {
    const blocks = editorState
      .getCurrentContent()
      .getBlockMap()
      .map((singleBlock) =>
        singleBlock.set(
          'characterList',
          singleBlock.getCharacterList().map((charMetaData) => {
            if (!charMetaData) {
              return charMetaData;
            }
            const entity = charMetaData.getEntity();
            const style = charMetaData.getStyle();
            return CharacterMetadata.create({
              entity,
              style: style.intersect(retainInlineStyles),
            });
          })
        )
      )
      .toArray();

    return EditorState.createWithContent(ContentState.createFromBlockArray(blocks));
  };

  const removeFormatting = () => {
    const newState = removeInlineStyles(editorState);
    setEditorState(newState);
  };

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const editorData = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    if (editorData.length > 8) {
      setError(false);
    } else {
      setError(true);
    }
    setRichText(editorData);
  }, [editorState]);

  return (
    <Box sx={{ padding: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            sx={{
              minHeight: '250px',
              border: 'solid 1px #e6e6e6',
              borderRadius: '10px',
              justifyContent: 'unset',
              alignItems: 'unset',
              lineHeight: 'unset',
              textAlign: 'unset',
              fontSize: 'unset',
              fontWeight: 'unset',
              fontFamily: 'unset',
              color: 'unset',
            }}
            padding={1}
          >
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              {/* <Button variant="outlined" size="small" onClick={removeFormatting}>
                Remove Formatting
              </Button> */}
            </Stack>
            <Editor
              // eslint-disable-next-line react/jsx-no-bind
              // keyBindingFn={myKeyBindingFn}
              // handleKeyCommand={handleKeyCommand}
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName editor"
              onEditorStateChange={onEditorStateChange}
              onBlur={() => setError(true)}
              toolbar={{
                options: ['inline', 'blockType', 'list', 'link', 'colorPicker', 'history'],
                inline: {
                  inDropdown: false,
                  options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
                blockType: {
                  inDropdown: true,
                  options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                },
                list: {
                  inDropdown: false,
                  options: ['unordered', 'ordered'],
                },
                textAlign: {
                  inDropdown: undefined,
                  options: [],
                },
                link: {
                  inDropdown: false,
                  defaultTargetOption: '_blank',
                },
                colorPicker: {
                  inDropdown: false,
                },
                remove: { icon: undefined, className: 'toolbar-remove', component: undefined },
                history: {
                  inDropdown: false,
                  options: ['undo', 'redo'],
                },
              }}
            />
          </Box>
          {error && (
            <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
              <Typography variant="caption" color="error">
                Required
              </Typography>
            </Stack>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
