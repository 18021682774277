import {
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ContentCopy, DeleteForever, Person, Reply, Visibility } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/CommentStatus';
import PopupDelete from '../components/popups/CommentDelete';
import PopupReply from '../components/popups/CommentReply';

function CommentView() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const adminId = accountData._id;
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [comment, setComment] = useState({});
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [statusChangeOpen, setStatusChangeOpen] = useState(false);
  const [replyOpen, setReplyOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const getComment = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/comment/admin`, {
        params: { id },
      });
      console.log('comment', res);
      setComment(res.data.data.comment);
      if (res.data.data.comment && res.data.data.comment._id) {
        setIsLoading(false);
      } else {
        navigate(-1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleStatusChange1 = (event, item) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    setStatusChangeOpen(true);
  };

  const deleteAuthorPopup = () => {
    setOpen(true);
  };

  const deleteAuthorPopup1 = () => {
    setDeleteOpen(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    setOpen(false);
    setOpen1(false);
    setStatusChangeOpen(false);
    setReplyOpen(false);
    if (refresh) {
      getComment();
      toast(message);
    } else if (message) {
      toast.error(message);
    }
  };
  const handleDelete = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    setOpen(false);
    setOpen1(false);
    setStatusChangeOpen(false);
    setReplyOpen(false);
    setDeleteOpen(false);
    if (refresh) {
      toast(message);
      navigate(-1);
    } else if (message) {
      toast.error(message);
    }
  };
  const replyPopup = () => {
    setOpen1(true);
  };

  const replyPopup1 = () => {
    setReplyOpen(true);
  };

  useEffect(() => {
    getComment();
  }, []);

  return (
    <Page title="Comment Details">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Stack direction="column" spacing={2}>
          <Stack
            direction="column"
            key={id}
            padding={2}
            sx={{
              boxShadow: 'rgba(0, 0, 0, 0.203) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
              minWidth: '800px',
              borderRadius: '10px',
              marginBottom: '10px',
              backgroundColor: 'white',
            }}
          >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography varaint="caption" color="text.secondary">
                  {moment(comment.createdAt).format('YYYY-MM-DD')}
                </Typography>
                {!comment.adminName ? (
                  <Chip
                    size="small"
                    sx={{ backgroundColor: comment.accType === 'AUTHENTICATED' ? '#10ba68' : '#ad2742' }}
                    color="primary"
                    label={comment.accType}
                  />
                ) : (
                  <Chip size="small" color="primary" label={'ADMIN'} />
                )}
              </Stack>
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                <CopyToClipboard text={comment.feedId} onCopy={() => toast.info('Feed ID copied')}>
                  <Button startIcon={<ContentCopy sx={{ fontSize: '20px' }} />} color="brownee">
                    Feed ID
                  </Button>
                </CopyToClipboard>
                <Stack direction="row" alignItems="center">
                  <Iconify
                    icon="ri:spam-2-fill"
                    sx={{
                      color: '#ff0000',
                      fontSize: '24px',
                    }}
                  />
                  <Typography variant="p" color="text.secondary" mr={0.5}>
                    Spam:
                  </Typography>
                  <Typography variant="p">{comment.spam}</Typography>
                </Stack>
                {isSuperAdmin || adminId === comment?.feedAuthorAdminId ? (
                  <FormControl sx={{ minWidth: 100 }}>
                    <Select
                      size="small"
                      sx={{
                        height: '25px',
                        boxShadow: 'none',
                      }}
                      value={comment.isBlocked}
                      onChange={(e) => handleStatusChange1(e, comment)}
                    >
                      <MenuItem value="false">Active</MenuItem>
                      <MenuItem value="true">Blocked</MenuItem>
                    </Select>
                    {statusChangeOpen && <Popup item={comment} handleClose={handleClose} />}
                  </FormControl>
                ) : (
                  <Chip
                    size="small"
                    sx={{ backgroundColor: comment.isBlocked ? '#ad2742' : '#10ba68' }}
                    color="primary"
                    label={comment.isBlocked ? 'Blocked' : 'Active'}
                  />
                )}
                {replyOpen && <PopupReply item={comment} handleClose={handleClose} />}
                {deleteOpen && <PopupDelete item={comment} handleClose={handleDelete} />}
                <Button
                  color="greenish"
                  variant="contained"
                  size="small"
                  sx={{
                    height: '25px',
                    boxShadow: 'none',
                  }}
                  startIcon={<Visibility />}
                  onClick={() => navigate(`/dashboard/feed/view/${comment.feedId}`)}
                >
                  Feed
                </Button>
                {isSuperAdmin && (
                  <Button
                    variant="contained"
                    color="error"
                    size="small"
                    startIcon={<DeleteForever />}
                    sx={{
                      height: '25px',
                      boxShadow: 'none',
                    }}
                    onClick={deleteAuthorPopup1}
                  >
                    Delete
                  </Button>
                )}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <CopyToClipboard text={comment.userId} onCopy={() => toast.info('User ID copied')}>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
                  <Avatar
                    sx={{
                      width: '50px',
                      height: '50px',
                    }}
                    src={comment.profilePic}
                  />
                  {!comment.adminName ? (
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {comment.accType === 'AUTHENTICATED' ? comment.username : comment?.metadata?.fullname}
                      </Typography>
                      <Typography variant="p" color="text.secondary">
                        {comment.accType === 'AUTHENTICATED' ? comment.email : comment?.metadata?.email}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {comment.adminName}
                      </Typography>
                      <Typography variant="p" color="text.secondary">
                        {comment.adminEmail}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </CopyToClipboard>
            </Stack>

            <Box
              sx={{
                padding: '5px',
                borderRadius: '5px',
                marginLeft: '60px',
                marginY: '10px',
              }}
            >
              <Typography variant="p" mt={1}>
                {comment.comment}
              </Typography>
            </Box>
            <Stack direction="row" justifyContent="flex-end" alignItems="center">
              <Button
                variant="contained"
                size="small"
                sx={{
                  height: '25px',
                  boxShadow: 'none',
                }}
                onClick={replyPopup1}
                startIcon={<Reply />}
              >
                Reply
              </Button>
            </Stack>
          </Stack>
          <Typography variant="h4" sx={{ minWidth: '300px' }}>
            REPLIES
          </Typography>
          {comment?.replies?.map((item, id) => (
            <Stack
              direction="column"
              key={id}
              padding={2}
              sx={{
                boxShadow: 'rgba(0, 0, 0, 0.203) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px',
                minWidth: '800px',
                borderRadius: '10px',
                marginBottom: '10px',
                backgroundColor: 'white',
              }}
              margin={2}
            >
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography varaint="caption" color="text.secondary">
                    {moment(item.createdAt).format('YYYY-MM-DD')}
                  </Typography>
                  {!item.authorName ? (
                    <Chip
                      size="small"
                      sx={{ backgroundColor: item.accType === 'AUTHENTICATED' ? '#10ba68' : '#ad2742' }}
                      color="primary"
                      label={item.accType}
                    />
                  ) : (
                    <Chip size="small" color="primary" label={'ADMIN'} />
                  )}
                </Stack>
                <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={4}>
                  {isSuperAdmin ? (
                    <FormControl sx={{ minWidth: 100 }}>
                      <Select
                        size="small"
                        value={item.isBlocked}
                        sx={{
                          height: '25px',
                          boxShadow: 'none',
                        }}
                        onChange={(e) => handleStatusChange(e, item, id)}
                      >
                        <MenuItem value="false">Active</MenuItem>
                        <MenuItem value="true">Blocked</MenuItem>
                      </Select>
                      {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                    </FormControl>
                  ) : (
                    <Chip
                      size="small"
                      sx={{ backgroundColor: item.isBlocked ? '#ad2742' : '#10ba68' }}
                      color="primary"
                      label={item.isBlocked ? 'Blocked' : 'Active'}
                    />
                  )}
                  {open1 && <PopupReply item={item} handleClose={handleClose} />}
                  {isSuperAdmin && (
                    <Button
                      variant="contained"
                      sx={{
                        height: '25px',
                        boxShadow: 'none',
                      }}
                      color="error"
                      startIcon={<DeleteForever />}
                      onClick={deleteAuthorPopup}
                    >
                      Delete
                    </Button>
                  )}
                  {open && <PopupDelete item={item} handleClose={handleClose} />}
                </Stack>
              </Stack>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar
                    sx={{
                      width: '50px',
                      height: '50px',
                    }}
                    src={item.authorName ? item?.authorProfilePic?.fileUrl : item.profilePic}
                  />
                  {!item.authorName ? (
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {item.accType === 'AUTHENTICATED' ? item.username : item?.metadata?.fullname}
                      </Typography>
                      <Typography variant="p" color="text.secondary">
                        {item.accType === 'AUTHENTICATED' ? item.email : item?.metadata?.email}
                      </Typography>
                    </Stack>
                  ) : (
                    <Stack direction="column">
                      <Typography
                        variant="p"
                        sx={{
                          fontWeight: 'bold',
                        }}
                      >
                        {item.authorName}
                      </Typography>
                      {/* <Typography variant="p" color="text.secondary">
                        {item.adminEmail}
                      </Typography> */}
                    </Stack>
                  )}
                </Stack>
              </Stack>

              <Box
                sx={{
                  padding: '5px',
                  borderRadius: '5px',
                  marginLeft: '60px',
                  marginY: '10px',
                }}
              >
                <Typography variant="p" mt={1}>
                  {item.comment}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Stack>
      )}
    </Page>
  );
}

export default CommentView;
