import axios from 'axios';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Close, Edit } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import Scrollbar from './Scrollbar';

function SettingsLinksTable() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [isLoading, setIsLoading] = useState(true);
  const [links, setLinks] = useState([]);
  const [selected, setSelected] = useState('');

  const formik = useFormik({
    initialValues: {
      xUrl: '',
      youtubeUrl: '',
      storeUrl: '',
      patreonUrl: '',
    },
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/settings/update`, values);
        toast(res.data.message);
        setSelected('');
        getSettingsLinks();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const handleEdit = (e, item) => {
    setSelected(item);
  };

  const setLinkValues = (data) => {
    formik.setFieldValue('xUrl', data.xUrl);
    formik.setFieldValue('youtubeUrl', data.youtubeUrl);
    formik.setFieldValue('storeUrl', data.storeUrl);
    formik.setFieldValue('patreonUrl', data.patreonUrl);
  };

  const getSettingsLinks = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings/links`);
      console.log(res.data);
      setIsLoading(false);
      setLinks(res.data.data);
      setLinkValues(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettingsLinks();
  }, []);

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <>
              {links ? (
                <Scrollbar>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>URL</TableCell>
                          <TableCell align="right">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell sx={{ width: '100px' }}>1</TableCell>
                          <TableCell>X </TableCell>
                          <TableCell>
                            {selected === 'X Url' ? (
                              <TextField
                                type="text"
                                label="X Url"
                                sx={{ width: '100%' }}
                                {...getFieldProps('xUrl')}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton onClick={() => setSelected('')}>
                                      <Close />
                                    </IconButton>
                                  ),
                                }}
                              />
                            ) : (
                              links.xUrl
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={(e) => handleEdit(e, 'X Url')}>
                              {selected === 'X Url' ? (
                                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                                  Submit
                                </LoadingButton>
                              ) : (
                                <Edit />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell sx={{ width: '100px' }}>2</TableCell>
                          <TableCell>Youtube </TableCell>
                          <TableCell>
                            {selected === 'Youtube Url' ? (
                              <TextField
                                type="text"
                                label="Youtube Url"
                                sx={{ width: '100%' }}
                                {...getFieldProps('youtubeUrl')}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton onClick={() => setSelected('')}>
                                      <Close />
                                    </IconButton>
                                  ),
                                }}
                              />
                            ) : (
                              links.youtubeUrl
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={(e) => handleEdit(e, 'Youtube Url')}>
                              {selected === 'Youtube Url' ? (
                                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                                  Submit
                                </LoadingButton>
                              ) : (
                                <Edit />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell sx={{ width: '100px' }}>3</TableCell>
                          <TableCell>Store </TableCell>
                          <TableCell>
                            {selected === 'Store Url' ? (
                              <TextField
                                type="text"
                                label="Store Url"
                                sx={{ width: '100%' }}
                                {...getFieldProps('storeUrl')}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton onClick={() => setSelected('')}>
                                      <Close />
                                    </IconButton>
                                  ),
                                }}
                              />
                            ) : (
                              links.storeUrl
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={(e) => handleEdit(e, 'Store Url')}>
                              {selected === 'Store Url' ? (
                                <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                                  Submit
                                </LoadingButton>
                              ) : (
                                <Edit />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell sx={{ width: '100px' }}>4</TableCell>
                          <TableCell>Patreon </TableCell>
                          <TableCell>
                            {selected === 'Patreon Url' ? (
                              <TextField
                                type="text"
                                label="Patreon Url"
                                sx={{ width: '100%' }}
                                {...getFieldProps('patreonUrl')}
                                InputProps={{
                                  endAdornment: (
                                    <IconButton onClick={() => setSelected('')}>
                                      <Close />
                                    </IconButton>
                                  ),
                                }}
                              />
                            ) : (
                              links.patreonUrl
                            )}
                          </TableCell>
                          <TableCell align="right">
                            {
                              <IconButton onClick={(e) => handleEdit(e, 'Patreon Url')}>
                                {selected === 'Patreon Url' ? (
                                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                                    Submit
                                  </LoadingButton>
                                ) : (
                                  <Edit />
                                )}
                              </IconButton>
                            }
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              ) : (
                <Box sx={{ width: '100%', marginTop: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        type="text"
                        label="X Url"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('xUrl')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        type="text"
                        label="Youtube Url"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('youtubeUrl')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        type="text"
                        label="Store Url"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('storeUrl')}
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        type="text"
                        label="Patreon Url"
                        fullWidth
                        sx={{ width: '100%' }}
                        {...getFieldProps('patreonUrl')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end">
                        <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                          Submit
                        </LoadingButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          </Form>
        </FormikProvider>
      )}
    </>
  );
}

export default SettingsLinksTable;
