import { Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import '../style.css';

export default function TextPreview({ item }) {
  const [htmlString, setHtmlString] = useState(item.text);
  const containerRef = useRef(null);



  return (
    <Box className="textContent markdown">
      <Box ref={containerRef} padding={0} dangerouslySetInnerHTML={{ __html: htmlString }} />
    </Box>
  );
}
