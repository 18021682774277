/* eslint-disable jsx-a11y/media-has-caption */
import {
  Button,
  Container,
  Card,
  Grid,
  Box,
  Stack,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

/// editor //
import { toast } from 'react-toastify';
import UpdateOeder from '../UpdateOeder';

export default function VideoSectionView({ setSection, feed, content, getFeed, index, feedOwner }) {
  const [error, setError] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const videoLinkSchema = Yup.object().shape({
    title: Yup.string(),
    link: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      title: content.title,
      link: content.videoLink,
    },
    validationSchema: videoLinkSchema,
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content/video-link`, {
          ...values,
          id: feed._id,
          contentId: content._id,
        });
        getFeed();
        setEditMode(false);
        toast(res.data.message);
        formik.resetForm();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const deleteSection = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/feed/admin/content`, {
        data: {
          id: feed._id,
          contentId: content._id,
        },
      });
      console.log('delete text section', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <>
      {/* delete popup  */}
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
          <Button onClick={deleteSection} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete popup  */}
      <Card sx={{ padding: 1 }}>
        {editMode && (
          <>
            {' '}
            <Typography variant="h4" sx={{ mb: 2 }}>
              Add Video Link
            </Typography>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      type="text"
                      label="Title"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('title')}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12} md={12}>
                    <TextField
                      type="text"
                      label="Link"
                      fullWidth
                      sx={{ width: '100%' }}
                      {...getFieldProps('link')}
                      error={Boolean(touched.link && errors.link)}
                      helperText={touched.link && errors.link}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                      <Button variant="outlined" onClick={() => setEditMode(false)}>
                        Cancel
                      </Button>
                      <LoadingButton variant="contained" size="small" type="submit" loading={isSubmitting}>
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </>
        )}
        {!editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {feedOwner && (
                <Stack direction="row" sx={{ width: '100%' }} justifyContent="space-between" spacing={1}>
                  <UpdateOeder index={index} getFeed={getFeed} feed={feed} />
                  <Stack spacing={1} direction={'row'}>
                    <LoadingButton
                      loading={buttonLoading}
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>
                    <Button size="small" variant="outlined" onClick={() => setEditMode(true)}>
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              {/* video player */}
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                paddingTop={3}
                sx={{ maxWidth: '600px', margin: '0 auto', width: '100%', height: '100%' }}
              >
                <iframe src={content.videoLink} title={content.title} allowFullScreen />
                <Typography sx={{ width: '100%', color: 'gray.700' }} variant="body2">
                  {content.title}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Card>
    </>
  );
}
