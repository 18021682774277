import { Link, Link as RouterLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';

// material
import {
  Card,
  Table,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  FormControl,
  Select,
  Icon,
  Container,
  Typography,
  MenuItem,
  TableContainer,
  TablePagination,
  TextField,
  Grid,
  Box,
  Tooltip,
  Avatar,
  IconButton,
  Popover,
} from '@mui/material';
import { Close, ContentCopy } from '@mui/icons-material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// components
import { useEffect, useState } from 'react';
// import { Button } from '../theme/Button';
import Iconify from '../components/Iconify';
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Popup from '../components/popups/UserStatus';
import Filter from '../components/filters/UserFilter';
import BackButton from '../components/BackButton';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Users() {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [pages, setPages] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const size = 10;
  const [options, setOptions] = useState({ size, page: 0 });
  const [input, setInput] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open3, setOpen3] = useState(false);

  const getUsers = async (options) => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/user/admin/all`, {
        params: options,
      });
      console.log('user posts', res);
      setUserData(res.data.data.records);
      setUserCount(res.data.data.maxRecords);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleStatusChange = (event, item, index) => {
    console.log('item', item);
    if (`${item.isBlocked}` === event.target.value) {
      return;
    }
    console.log(index);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    if (refresh) {
      getUsers(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getUsers(temp);
  };

  const goToPage = () => {
    if (input > Math.ceil(userCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getUsers(temp);
  };

  const handleOpenPopover = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndex1(index);
    setOpen3(true);
    event.stopPropagation();
  };

  const handleClosePopover = (event) => {
    setAnchorEl(null);
    setSelectedIndex1('');
    setOpen3(false);
    event.stopPropagation();
  };

  const getLoginType = (item) => {
    if (item.facebookId) {
      return 'Facebook';
    }
    if (item.googleId) {
      return 'Google';
    }
    return 'Email';
  };

  const applyFilters = (filter) => {
    // console.log('filter', filter);
    const temp = { page: 0, size };
    if (filter.username?.length) {
      temp.username = filter.username;
    }
    if (filter.userId?.length) {
      temp.userId = filter.userId;
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate;
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate;
    }
    if (filter.isBlocked?.length) {
      temp.isBlocked = filter.isBlocked;
    }
    if (filter.email?.length) {
      temp.email = filter.email;
    }
    setOptions(temp);
    setPages(0);
    getUsers(temp);
  };

  useEffect(() => {
    getUsers(options);
  }, []);

  return (
    <Page title="Audience">
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <Container maxWidth="xl">
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4">Audience</Typography>
            </Stack>
            <Card>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" spacing={2} padding={2}>
                  <TextField
                    size="small"
                    style={{ maxWidth: '100px', maxHeight: '35px' }}
                    type="number"
                    component="div"
                    label="Go to"
                    value={input}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        goToPage();
                      }
                    }}
                    onInput={(e) => setInput(e.target.value)}
                  />
                  <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                    Go
                  </Button>
                </Stack>
                <Stack padding={1} direction="row" justifyContent="flex-end" spacing={2} alignItems="center">
                  <Filter applyFilters={applyFilters} />
                </Stack>
              </Stack>

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    {/* <UserListHead /> */}
                    <TableHead>
                      <TableRow>
                        <TableCell>Sl No</TableCell>
                        <TableCell>Profile Picture</TableCell>
                        <TableCell>Fullname</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Login Type</TableCell>
                        <TableCell>Member Since</TableCell>
                        <TableCell>User ID</TableCell>
                        <TableCell>Status</TableCell>
                        {/* <TableCell>Actions</TableCell> */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userData?.map((item, id) => (
                        <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                          <TableCell>{pages * size + (id + 1)}</TableCell>

                          <TableCell>
                            <Avatar
                              alt="User"
                              src={item.profilePic}
                              onClick={(e) => handleOpenPopover(e, id)}
                              sx={{ width: 48, height: 48, cursor: 'pointer' }}
                            />
                            <Popover
                              open={open3 && selectedIndex1 === id}
                              anchorEl={anchorEl}
                              onClose={handleClosePopover}
                              disableRestoreFocus
                              anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                            >
                              <Box
                                sx={{
                                  p: 2,
                                  maxWidth: 280,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                  flexDirection: 'column',
                                }}
                              >
                                <IconButton
                                  onClick={handleClosePopover}
                                  sx={{
                                    position: 'absolute',
                                    top: 0,
                                    right: 0,
                                    color: 'text.secondary',
                                  }}
                                >
                                  <Close />
                                </IconButton>
                                <Avatar src={item.profilePic} alt="Avatar" sx={{ width: 200, height: 200 }} />
                                <Typography
                                  sx={{
                                    lineHeight: 1.2,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {item.username}
                                </Typography>
                              </Box>
                            </Popover>
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>
                            <CopyToClipboard text={item.username} onCopy={() => toast.info('Username copied')}>
                              <Stack
                                direction="row"
                                sx={{ cursor: 'pointer' }}
                                alignItems="center"
                                spacing={0.5}
                                justifyContent="flex-start"
                              >
                                <Typography variant="p">{item.username}</Typography>
                              </Stack>
                            </CopyToClipboard>
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>
                            <CopyToClipboard text={item.email} onCopy={() => toast.info('Email copied')}>
                              <Stack
                                direction="row"
                                sx={{ cursor: 'pointer' }}
                                alignItems="center"
                                spacing={0.5}
                                justifyContent="flex-start"
                              >
                                <Typography variant="p">{item.email}</Typography>
                              </Stack>
                            </CopyToClipboard>
                          </TableCell>
                          <TableCell>{getLoginType(item)}</TableCell>
                          <TableCell sx={{ minWidth: '100px' }}>
                            {Moment(item.createdAt).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell style={{ cursor: 'pointer' }}>
                            <Stack direction="column" spacing={0.5} width="80px">
                              <CopyToClipboard text={item._id} onCopy={() => toast.info('User id copied')}>
                                <Stack direction="row" alignItems="center" spacing={0.5} justifyContent="flex-start">
                                  <IconButton sx={{ margin: 0, padding: 0 }} color="primary">
                                    <ContentCopy sx={{ fontSize: '20px' }} />
                                  </IconButton>
                                  <Typography variant="p" color="primary">
                                    Copy ID
                                  </Typography>
                                </Stack>
                              </CopyToClipboard>
                            </Stack>
                          </TableCell>
                          <TableCell>
                            {isSuperAdmin ? (
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.isBlocked}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="false">Active</MenuItem>
                                  <MenuItem value="true">Blocked</MenuItem>
                                </Select>
                                {selectedIndex === id && <Popup item={item} handleClose={handleClose} />}
                              </FormControl>
                            ) : (
                              <Typography>{item.isBlocked ? 'Blocked' : 'Active'}</Typography>
                            )}
                          </TableCell>

                          {/* <TableCell>
                            <Box>
                              <Link to={`/dashboard/user/view/${item._id}`}>
                                <Icon sx={{ color: 'gray' }}>
                                  <Iconify icon="subway:eye" />
                                </Icon>
                              </Link>
                            </Box>
                          </TableCell> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <TablePagination
                rowsPerPageOptions={[]}
                component={'div'}
                showFirstButton
                showLastButton
                count={userCount}
                onPageChange={handlePageChange}
                rowsPerPage={size}
                page={pages}
              />
            </Card>
          </Container>
        </>
      )}
    </Page>
  );
}
