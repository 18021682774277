import { Button, Container, Card, Grid, Box, Stack, Typography, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

export default function EmbedSection({ setSection, feed, getFeed }) {
  const embedSchema = Yup.object().shape({
    title: Yup.string(),
    embedCode: Yup.string().required('Code is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      embedCode: '',
    },
    validationSchema: embedSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/feed/admin/content/embed`, {
          ...values,
          id: feed._id,
        });
        getFeed();
        setSection('');
        toast(res.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Card sx={{ padding: 1 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Add Embed Code
      </Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="text"
                label="Title"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('title')}
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <TextField
                type="text"
                multiline
                label="Embed Code"
                fullWidth
                sx={{ width: '100%' }}
                {...getFieldProps('embedCode')}
                error={Boolean(touched.embedCode && errors.embedCode)}
                helperText={touched.embedCode && errors.embedCode}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                <Button size="small" variant="outlined" onClick={() => setSection('')}>
                  Cancel
                </Button>
                <LoadingButton variant="contained" size="small" type="submit" loading={isSubmitting}>
                  Submit
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Card>
  );
}
