import { Edit } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export default function EditAuthor({ profile, setEditMode, getProfile, setImage }) {
  const formik = useFormik({
    initialValues: {
      id: profile?._id || '',
      name: profile?.name || '',
    },
    onSubmit: async () => {
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/author`, values, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        setEditMode(false);
        getProfile();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    if (values.profilePic) {
      setImage(URL.createObjectURL(values.profilePic));
    }
  }, [values.profilePic]);

  return (
    <Grid item xs={12} sm={6} md={7} lg={8} padding={2}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
        spacing={2}
        marginLeft={2}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" margin={1} sx={{ width: '100%' }}>
          <Typography variant="h4" gutterBottom>
            EDIT ACCOUNT DETAILS
          </Typography>
        </Stack>

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="text"
                  size="small"
                  label="Name"
                  fullWidth
                  sx={{ width: '100%' }}
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  type="file"
                  size="small"
                  label="Profile Picture"
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: 'image/jpeg, image/jpg, image/png' }}
                  name="profilePic"
                  onChange={(event) => formik.setFieldValue('author', event.target.files[0])}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" sx={{ width: '100%' }} spacing={2} justifyContent="end">
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setEditMode(false);
                      setImage(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton variant="contained" type="submit" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Stack>
    </Grid>
  );
}
