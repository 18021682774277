import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#eeeeeea6',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#3F4750',
  600: '#637381',
  700: '#454F5B',
  800: '#06080A',
  900: '#040507',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GREY1 = {
  0: '#040507',
  100: '#0e1319',
  200: '#1c2027',
  300: '#303a42',
  400: '#3F4750',
  500: '#C4CDD5',
  600: '#DFE3E8',
  700: '#F4F6F8',
  800: '#F9FAFB',
  900: '#FFFFFF',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const PRIMARY = {
  lighter: '#f9e5e5',
  light: '#79dca7',
  main: '#009245',
  dark: '#03592b',
  darker: '#012110',
  contrastText: '#fff',
};

const GREEN = {
  lighter: '#dcd9d9',
  light: '#A6BB8D',
  main: '#61876E',
  dark: '#3C6255',
  darker: '#223931',
  contrastText: '#fff',
};

const PURPLE = {
  lighter: '#e1d9cf',
  light: '#cac4be',
  main: '#886f97',
  dark: '#4a3e2f',
  darker: '#2c183d',
  contrastText: '#fff',
};
const ORANGE = {
  lighter: '#eee6d7',
  light: '#E6D5B8',
  main: '#E45826',
  dark: '#875c00',
  darker: '#1B1A17',
  contrastText: '#fff',
};

const ORANGE1 = {
  lighter: '#eee6d7',
  light: '#E6D5B8',
  main: '#ab5b3f',
  dark: '#6b562a',
  darker: '#1B1A17',
  contrastText: '#fff',
};


const BALANCED = {
  lighter: '#EDE4E0',
  light: '#C8DBBE',
  main: '#726152',
  dark: '#383228',
  darker: '#221d16',
  contrastText: '#fff',
};

const BROWN = {
  lighter: '#A77979',
  light: '#704F4F',
  main: '#553939',
  dark: '#472D2D',
  darker: '#301d1d',
  contrastText: '#fff',
};

const RED = {
  lighter: '#FF5733',
  light: '#C70039',
  main: '#900C3F',
  dark: '#511845',
  darker: '#300d29',
  contrastText: '#fff',
};

const PRIMARY1 = {
  lighter: '#230000',
  light: '#560000',
  main: '#c61717',
  dark: '#FA8484',
  darker: '#FFE2E2',
  contrastText: '#000',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#fff',
};

const SECONDARY1 = {
  lighter: '#091A7A',
  light: '#1939B7',
  main: '#3366FF',
  dark: '#84A9FF',
  darker: '#D6E4FF',
  contrastText: '#000',
};

const INFO = {
  lighter: '#FAD4D4',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#8a6161',
  darker: '#312020',
  contrastText: '#fff',
};
const INFO1 = {
  darker: '#D0F2FF',
  dark: '#74CAFF',
  main: '#1890FF',
  light: '#0C53B7',
  lighter: '#021338',
  contrastText: '#000',
};

const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800],
};
const SUCCESS1 = {
  darker: '#E9FCD4',
  dark: '#AAF27F',
  main: '#54D62C',
  light: '#229A16',
  lighter: '#08660D',
  contrastText: GREY[100],
};

const WARNING = {
  lighter: '#FFD5BE',
  light: '#FF8C8C',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
};

const WARNING1 = {
  darker: '#FFF7CD',
  dark: '#FFE16A',
  main: '#FFC107',
  light: '#986b01',
  lighter: '#5c3c01',
  contrastText: GREY[100],
};

const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff',
};

const NEWBLUE = {
  lighter: '#bcd1f6',
  light: '#567dc6',
  main: '#2957aa',
  dark: '#204283',
  darker: '#071632',
  contrastText: '#fff',
};

const ERROR1 = {
  darker: '#FFE7D9',
  dark: '#FFA48D',
  main: '#FF4842',
  light: '#831221',
  lighter: '#4f071e',
  contrastText: '#000',
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const GRADIENTS1 = {
  primary: createGradient(PRIMARY1.light, PRIMARY1.main),
  info: createGradient(INFO1.light, INFO1.main),
  success: createGradient(SUCCESS1.light, SUCCESS1.main),
  warning: createGradient(WARNING1.light, WARNING1.main),
  error: createGradient(ERROR1.light, ERROR1.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  greenish: GREEN,
  redish: RED,
  purplee: PURPLE,
  orangee: ORANGE,
  orangee1: ORANGE1,
  brownee: BROWN,
  balanced: BALANCED,
  grey: GREY,
  gradients: GRADIENTS1,
  newblue: NEWBLUE,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[800] },
  background: { paper: '#fff', default: GREY[100], neutral: GREY[200] },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette1 = {
  common: { black: '#fff', white: '#000' },
  primary: { ...PRIMARY1 },
  secondary: { ...SECONDARY1 },
  info: { ...INFO1 },
  success: { ...SUCCESS1 },
  warning: { ...WARNING1 },
  error: { ...ERROR1 },
  grey: GREY1,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY1[500_24],
  text: { primary: GREY1[800], secondary: GREY1[600], disabled: GREY1[800] },
  background: { paper: '#000', default: GREY1[100], neutral: GREY1[200] },
  action: {
    active: GREY1[600],
    hover: GREY1[500_8],
    selected: GREY1[500_16],
    disabled: GREY1[500_80],
    disabledBackground: GREY1[500_24],
    focus: GREY1[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

/// export

export default palette;

export { palette1 };
