import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { useState } from 'react';
import axios from 'axios';

const NameUpdatePopup= (props) => {
  const [newName, setNewName] = useState(props.item.name);

  const updatePriority = () => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/country/admin`, {
        id: props.item._id,
        name: newName,
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false,
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };

  const open = true;
  return (
    <>
      <Dialog onClose={props.handleClose} open={open}>
        <DialogTitle>Priority Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the name for this category:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>props.handleClose(false)}>Cancel</Button>
          <Button onClick={updatePriority}>Update</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default NameUpdatePopup;
