import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Avatar,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
/// file pond //
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image to match crop
import { Delete, OpenInNew, Visibility, Warning } from '@mui/icons-material';
import Iconify from '../Iconify';
import PopupDelete from '../popups/FeedDelete';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform);

export default function MainSection({ feed, getFeed }) {
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const adminId = accountData._id;
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [files, setFiles] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const truevalue = true;
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [author, setAuthor] = useState({});
  const [open5, setOpen5] = useState(false);
  const [loading5, setLoading5] = useState(true);
  const [feedOwner, setFeedOwner] = useState(false);

  const getCountries = async (options) => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/country/admin/all/names`);
      console.log('countries', res);
      setCountries(res.data.data.countries);
    } catch (error) {
      console.log(error);
    }
  };

  const superAdminGetAuthors = async () => {
    try {
      setLoading5(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/author/super-admin/active/all`);
      console.log('authors', res);
      setAuthors(res.data.data);
      setLoading5(false);
    } catch (error) {
      console.log(error);
    }
  };

  const adminGetHisAuthor = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/author/admin/active`);
      console.log('author', res);
      setAuthor(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (author._id) {
      formik.setFieldValue('authorId', author._id);
    }
  }, [author]);

  const feedSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    countryId: Yup.array(),
    readingTime: Yup.number().min(1).required('Reading Time is required'),
    description: Yup.string().required('Description is required'),
  });

  const formik = useFormik({
    initialValues: {
      title: feed.title,
      countryId: feed.countryId,
      imageDescription: feed.imageDescription,
      description: feed.description,
      summary: feed.summary,
      isBlocked: feed.isBlocked,
      readingTime: feed.readingTime,
      keywords: '',
      date: feed?.createdAt ? moment(feed.createdAt).format('YYYY-MM-DD') : '',
      url: feed.url || '',
      authorId: feed.authorId,
    },
    validationSchema: feedSchema,
    onSubmit: async () => {
      const data = new FormData();
      if (values.keywords.length > 0) {
        let keywords = values.keywords.split(',');
        keywords = keywords.map((item) => item.trim());
        keywords = keywords.filter((item) => item.length > 0);
        keywords = [...new Set(keywords)];
        keywords.forEach((item) => {
          data.append('keywords', item);
        });
      }
      if (values.date) {
        data.append('date', values.date);
      }
      data.append('title', values.title);
      data.append('countryId', values.countryId);
      data.append('imageDescription', values.imageDescription);
      data.append('authorId', values.authorId);
      data.append('description', values.description);
      data.append('summary', values.summary);
      data.append('isBlocked', values.isBlocked);
      data.append('readingTime', values.readingTime);
      data.append('id', feed._id);
      data.append('url', values.url);
      files.forEach((item) => {
        data.append('image', item.file);
      });
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        resetForm();
        setFiles([]);
        getFeed();
        setEditMode(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const handleDelete = () => {
    setOpenDelete(true);
  };

  const handleClose = (refresh = false, message = '') => {
    setOpenDelete(false);
    if (refresh) {
      navigate('/dashboard/feed', { replace: true });
    }
    if (message) {
      toast(message);
    }
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  useEffect(() => {
    const image = [];
    files.forEach((item) => {
      image.push(item.file);
    });
    formik.setFieldValue('image', image);
  }, [files]);

  useEffect(() => {
    getCountries();
    if (isSuperAdmin) {
      superAdminGetAuthors();
    } else {
      adminGetHisAuthor();
    }
  }, []);

  useEffect(() => {
    if (feed.keywords && feed.keywords.length > 0) {
      const keywords = feed.keywords.join(',');
      formik.setFieldValue('keywords', keywords);
    }
  }, [feed.keywords]);

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (selectedCountries.length > 0) {
      const countryIds = selectedCountries.map((item) => item._id);
      formik.setFieldValue('countryId', countryIds);
    } else {
      formik.setFieldValue('countryId', []);
    }
  }, [selectedCountries]);

  const handleTableCellClick = () => {
    if (feed.isBlocked) {
      return;
    }
    const url = `${process.env.REACT_APP_USER_URL}/${feed.url || feed._id}`;
    window.open(url, '_blank');
  };

  useEffect(() => {
    // Convert countryIds to selectedCountries
    if (feed.countryId && feed.countryId.length > 0) {
      const matchedCountries = countries.filter((country) => feed.countryId.includes(country._id));
      setSelectedCountries(matchedCountries);
    }
  }, [feed.countryId, countries]);

  useEffect(() => {
    if ((feed?.adminId && feed?.adminId === adminId) || isSuperAdmin) {
      setFeedOwner(true);
    } else {
      setFeedOwner(false);
    }
  }, [feed]);

  return (
    <Card>
      <Stack direction={'row'} justifyContent="space-between" padding={1}>
        <Stack direction={'row'} alignItems="center" spacing={5}>
          {editMode && feedOwner && (
            <Typography paddingX={1} variant="h4">
              Edit Feed
            </Typography>
          )}
          {!editMode && (
            <Stack direction={'row'} alignItems="center" spacing={2}>
              <Typography paddingX={1} variant="h4">
                View Feed
              </Typography>
              {!feed?.isBlocked && (
                <Button
                  onClick={handleTableCellClick}
                  size="small"
                  variant="outlined"
                  sx={{ height: '25px' }}
                  startIcon={<OpenInNew />}
                  color="primary"
                >
                  Open
                </Button>
              )}
            </Stack>
          )}
          {feedOwner && (
            <Button
              size="small"
              variant="outlined"
              sx={{ height: '25px' }}
              onClick={handleDelete}
              startIcon={<Delete />}
              color='error'
            >
              Delete
            </Button>
          )}
        </Stack>
        {openDelete && <PopupDelete item={feed} handleClose={handleClose} />}
        <Stack direction={'row'} alignItems="center" spacing={2}>
          {!editMode && feedOwner && (
            <Button variant="outlined" size="small" onClick={() => setEditMode(true)}>
              Edit
            </Button>
          )}
          {/* <Iconify style={{ cursor: 'pointer', fontSize: '20px' }} onClick={() => navigate(-1)} icon="fe:close" /> */}
        </Stack>
      </Stack>
      <Box sx={{ paddingX: 2, paddingY: 3 }}>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Title"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('title')}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              {!editMode && (
                <Grid item xs={12} lg={5} md={6}>
                  <TextField
                    value={feed?.authorName}
                    type="text"
                    label="Author"
                    fullWidth
                    size="small"
                    disabled
                    sx={{ width: '100%' }}
                  />
                </Grid>
              )}
              {isSuperAdmin && editMode && (
                <Grid item xs={6} lg={3} md={4}>
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    open={open5}
                    onOpen={() => {
                      setOpen5(true);
                    }}
                    onClose={() => {
                      setOpen5(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    getOptionLabel={(option) => option.name}
                    options={authors}
                    onChange={(_, value) => {
                      if (value) {
                        formik.setFieldValue('authorId', value._id);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Author"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={Boolean(touched.authorId && errors.authorId)}
                        helperText={touched.authorId && errors.authorId}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <Avatar
                            sx={{ width: 35, height: 35, cursor: 'pointer' }}
                            src={option?.profilePic && option.profilePic.fileUrl}
                          />
                          <Stack direction={'column'}>
                            <Typography variant="body2" sx={{ fontWeight: 'bold', lineHeight: '1.1' }}>
                              {option.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      </li>
                    )}
                  />
                </Grid>
              )}
              {!isSuperAdmin && editMode && (
                <Grid item xs={6} lg={3} md={4}>
                  <TextField
                    type="text"
                    label="Author"
                    fullWidth
                    sx={{ width: '100%' }}
                    value={author.name}
                    disabled
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              )}

              <Grid item xs={12} lg={7} md={6}>
                {editMode && (
                  <Autocomplete
                    id="asynchronous-demo"
                    fullWidth
                    multiple
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    isOptionEqualToValue={(option, value) => option._id === value._id}
                    getOptionLabel={(option) => option.name}
                    options={countries}
                    value={selectedCountries}
                    onChange={(_, value) => setSelectedCountries(value)}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(touched.countryId && errors.countryId)}
                        helperText={touched.countryId && errors.countryId}
                        disabled={!editMode}
                        size="small"
                        label="Categories"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
                {!editMode && (
                  <TextField
                    type="text"
                    size="small"
                    label="Category"
                    disabled={!editMode}
                    fullWidth
                    value={feed.countryName}
                  />
                )}
              </Grid>

              <Grid item xs={6} lg={4} md={4} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  label="Reading Time"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  placeholder="Minutes"
                  sx={{ width: '100%' }}
                  {...getFieldProps('readingTime')}
                  error={Boolean(touched.readingTime && errors.readingTime)}
                  helperText={touched.readingTime && errors.readingTime}
                />
              </Grid>
              <Grid item xs={6} lg={4} md={4} sm={4}>
                <TextField
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  label="Pinned"
                  disabled
                  fullWidth
                  sx={{ width: '100%' }}
                  value={feed?.isPopular ? 'Yes' : 'No'}
                />
              </Grid>
              <Grid item xs={6} lg={4} md={4} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  select
                  size="small"
                  label="Status"
                  fullWidth
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('isBlocked')}
                  error={Boolean(touched.isBlocked && errors.isBlocked)}
                  helperText={touched.isBlocked && errors.isBlocked}
                >
                  <MenuItem value={'true'}>Blocked</MenuItem>
                  <MenuItem value={'false'}>Active</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} lg={4} md={4} sm={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  type="date"
                  size="small"
                  label="Publish Date"
                  fullWidth
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('date')}
                  error={Boolean(touched.date && errors.date)}
                  helperText={touched.date && errors.date}
                />
              </Grid>
              <Grid item xs={6} lg={4} md={4} sm={6}>
                {feed?.isCountryBlocked && (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<Warning />}
                    color="error"
                    sx={{ height: '100%', pointerEvents: 'none' }}
                  >
                    Category Blocked
                  </Button>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Description"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  multiline
                  rows={4}
                  {...getFieldProps('description')}
                  error={Boolean(touched.description && errors.description)}
                  helperText={touched.description && errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Summary"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  multiline
                  rows={4}
                  {...getFieldProps('summary')}
                  error={Boolean(touched.summary && errors.summary)}
                  helperText={touched.summary && errors.summary}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="URL"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('url')}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  label="Keywords"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('keywords')}
                  error={Boolean(touched.keywords && errors.keywords)}
                  helperText={touched.keywords && errors.keywords}
                />
              </Grid>
              {editMode && (
                <Grid item xs={12}>
                  <FilePond
                    files={files}
                    onupdatefiles={setFiles}
                    allowMultiple
                    maxFiles={5}
                    credits={false}
                    allowImagePreview
                    allowImageCrop={truevalue}
                    allowImageTransform
                    imageCropAspectRatio={'5:3'}
                    name="files"
                    labelIdle="Drag & Drop Images. Keep 5:3 Aspect Ratio"
                  />
                </Grid>
              )}
              {!editMode && (
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {feed.images.map((item, key) => (
                      <Grid key={key} item xs={6} lg={3} md={4} sm={4}>
                        <Box
                          component="img"
                          src={item?.fileURL}
                          sx={{
                            width: '100%',
                            objectFit: 'cover',
                            borderRadius: '10px',
                          }}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} mb={2}>
                <TextField
                  type="text"
                  label="Image Description"
                  fullWidth
                  size="small"
                  disabled={!editMode}
                  sx={{ width: '100%' }}
                  {...getFieldProps('imageDescription')}
                  error={Boolean(touched.imageDescription && errors.imageDescription)}
                  helperText={touched.imageDescription && errors.imageDescription}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={2}>
                {editMode && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      setEditMode(false);
                      resetForm();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {editMode && (
                  <LoadingButton variant="contained" type="submit" size="small" loading={isSubmitting}>
                    Submit
                  </LoadingButton>
                )}
              </Stack>
            </Grid>
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
