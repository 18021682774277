import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import Moment from 'moment';
// material
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  CircularProgress,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  FormControl,
} from '@mui/material';
import { toast } from 'react-toastify';
import { RemoveRedEye } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import Filter from '../components/filters/Messages';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import Popup from '../components/popups/MessageStatusChange';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

export default function Messages() {
  const navigate = useNavigate();
  const accountData = JSON.parse(localStorage.getItem('profile'));
  const isSuperAdmin = accountData.accType === 'SUPER_ADMIN';
  const [messageData, setMessageData] = useState();
  const [name, setName] = useState('');
  const [pages, setPages] = useState(0);
  const [messageCount, setMessageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedIndex1, setSelectedIndex1] = useState();
  const [size, setSize] = useState(10);
  const [options, setOptions] = useState({ size, page: 0 });
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState(1);
  const [status, setStatus] = useState('');

  const handleStatusChange = (event, item, index) => {
    if (item.status === event.target.value) {
      return;
    }
    setStatus(event.target.value);
    setSelectedIndex(index);
  };

  const handleClose = (refresh = false, message = '') => {
    setSelectedIndex();
    setSelectedIndex1();
    if (refresh) {
      getMessages(options);
    }
    if (message) {
      toast(message);
    }
  };

  const handlePageChange = (event, newPage) => {
    const temp = { ...options, page: newPage };
    setPages(newPage);
    setOptions(temp);
    setIsLoading(true);
    getMessages(temp);
  };

  const getMessages = (options) => {
    setIsLoading(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}/contact-us`, {
        params: options,
      })
      .then((res) => {
        console.log('res', res);
        if (res.data.isError === false) {
          setIsLoading(false);
          setMessageCount(res.data.data.maxRecords);
          setMessageData(res.data.data.messages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const getMessagesWithoutLoading = (options) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/contact-us`, {
        params: options,
      })
      .then((res) => { 
        console.log('res', res);
        if (res.data.isError === false) {
          setMessageCount(res.data.data.maxRecords);
          setMessageData(res.data.data.messages);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const goToPage = () => {
    console.log('hi');
    if (input > Math.ceil(messageCount / size)) {
      return;
    }
    setInput(input > 0 ? input : 1);
    setPages(input - 1 >= 0 ? input - 1 : 0);
    const temp = { ...options, page: input - 1 };
    setOptions(temp);
    getMessages(temp);
  };

  const onSearch = (e) => {
    e.preventDefault();
    setName(e.target.value);
    const temp = { ...options, page: 0, name: e.target.value };
    setOptions(temp);
    setPages(0);
    getMessagesWithoutLoading(temp);
  };

  const applyFilters = (filter) => {
    const temp = { page: 0, size };
    if (filter.status) {
      temp.status = filter.status.trim();
    }
    if (filter.name) {
      temp.name = filter.name.trim();
    }
    if (filter.email) {
      temp.email = filter.email.trim();
    }
    if (filter.startDate) {
      temp.startDate = filter.startDate.trim();
    }
    if (filter.endDate) {
      temp.endDate = filter.endDate.trim();
    }

    setOptions(temp);
    setPages(0);
    getMessages(temp);
  };

  useEffect(() => {
    getMessages(options);
  }, []);

  useEffect(() => {
    const temp = { ...options, size, page: 0 };
    setPages(0);
    setOptions(temp);
    getMessages(temp);
  }, [size]);

  return (
    <>
      <Page title="Contact Us">
        {isLoading ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <CircularProgress />
          </div>
        ) : (
          <>
            <Container maxWidth="xl">
              <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ marginBottom: 1 }}>
                <Typography variant="h4" gutterBottom>
                  Contact Us
                </Typography>
              </Stack>
              <Card>
                <Stack direction="row" justifyContent="space-between" alignItems="center" padding={2}>
                  {/* <Stack direction="row" spacing={2} >
                    <TextField
                      size="small"
                      style={{ maxWidth: '100px', maxHeight: '35px' }}
                      type="number"
                      component="div"
                      label="Go to"
                      value={input}
                      onInput={(e) => setInput(e.target.value)}
                    />
                    <Button variant="contained" onClick={goToPage} style={{ maxHeight: '35px' }}>
                      Go
                    </Button>
                  </Stack> */}
                  <Stack direction="row" spacing={2}>
                    <SearchStyle
                      value={name}
                      onChange={onSearch}
                      placeholder="Search name..."
                      startAdornment={
                        <InputAdornment position="start">
                          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                        </InputAdornment>
                      }
                    />
                  </Stack>
                  <Filter applyFilters={applyFilters} />
                </Stack>

                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Sl No</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {messageData?.map((item, id) => (
                          <TableRow key={id} sx={{ '&:hover': { backgroundColor: '#FFE2E2' } }}>
                            <TableCell>{id + 1}</TableCell>
                            <TableCell>{Moment(item.createdAt).format('DD/MM/YYYY')}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  maxWidth: '300px',
                                  overflow: 'hidden',
                                  whiteSpace: 'wrap',
                                  textOverflow: 'ellipsis',
                                  wordBreak: 'break-all',
                                }}
                              >
                                {item?.message?.length > 150 ? `${item?.message?.substring(0, 150)}...` : item?.message}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ minWidth: 100 }}>
                                <Select
                                  size="small"
                                  value={item.status}
                                  onChange={(e) => handleStatusChange(e, item, id)}
                                >
                                  <MenuItem value="PENDING">PENDING</MenuItem>
                                  <MenuItem value="REVIEWED">REVIEWED</MenuItem>
                                </Select>
                                {selectedIndex === id && (
                                  <Popup item={item} status={status} handleClose={handleClose} />
                                )}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="primary"
                                onClick={() => navigate(`/dashboard/message/view/${item._id}`)}
                              >
                                <RemoveRedEye />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 30]}
                  onRowsPerPageChange={(e) => {
                    setSize(e.target.value);
                  }}
                  component={'div'}
                  count={messageCount}
                  showFirstButton
                  showLastButton
                  onPageChange={handlePageChange}
                  rowsPerPage={size}
                  page={pages}
                />
              </Card>
            </Container>
          </>
        )}
      </Page>
    </>
  );
}
