import { Button, Card, Container, Grid, Typography } from '@mui/material';
import Iconify from '../Iconify';

export default function AddNewSection({ setSection }) {
  return (
    <Card sx={{ padding: 1 }}>
      <Container maxWidth="lg">
        <Typography textAlign="center" variant="h6" gutterBottom>Add New Section</Typography>
        <Grid container spacing={3} mb={2}>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="cil:text-square" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('TEXT')}
              color="redish"
            >
              TEXT
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="mdi:image-multiple" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('IMAGE')}
              color="orangee"
            >
              IMAGE
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="mdi:image-multiple" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('VIDEO')}
              color="orangee1"
            >
              VIDEO
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="bxs:pie-chart-alt-2" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('PIE_CHART')}
              color="greenish"
            >
              PIE CHART
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="ri:bar-chart-2-fill" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('BAR_GRAPH')}
              color="brownee"
            >
              BAR GRAPH
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="ic:sharp-ondemand-video" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('VIDEO_LINK')}
              color="balanced"
            >
              VIDEO LINK
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={2.4} lg={2.4} >
            <Button
              startIcon={<Iconify icon="icomoon-free:embed2" />}
              sx={{ width: '100%' }}
              variant="contained"
              onClick={() => setSection('EMBED_CODE')}
              color="newblue"
            >
              EMBED 
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
}
