import React from 'react'
import { PieChartNoCard } from '../sections/@dashboard/app';

export default function PieChartPreview({item}) {
  return (
    <>
    <PieChartNoCard title={item.title} subheader={item.description}  chartData={item.pieChart} />
    </>
  )
}
