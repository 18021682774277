import { Add, Delete, DeleteOutlineSharp } from '@mui/icons-material';
import { Button, Container, Card, Grid, TextField, Typography, Stack, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

export default function PieChartSection({ setSection, feed, getFeed }) {
  const [formData, setFormData] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newData = [...formData];
    newData[index][name] = value;
    setFormData(newData);
  };

  const handleAdd = () => {
    setFormData([...formData, { name: '', value: '' }]);
  };

  const handleRemove = (index) => {
    const newData = [...formData];
    newData.splice(index, 1);
    setFormData(newData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const filteredData = formData.filter((item) => item.label !== '' && item.value !== '');

    if (!filteredData.length) return;
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/feed/admin/content/pie-chart`, {
        id: feed._id,
        title,
        content: filteredData,
        description,
      });
      console.log('feed', res);
      toast(res.data.message);
      setSection(null);
      getFeed();
    } catch (error) {
      console.log(error);
      toast(error.response.data.message);
    }
  };

  return (
    <Card sx={{ padding: { xs: 0.5, sm: 1 } }}>
      <Typography variant="h6" gutterBottom>
        Add Pie Chart Data
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={1} mb={2}>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Title"
              fullWidth
              size="small"
              variant="outlined"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              label="Description"
              fullWidth
              size="small"
              variant="outlined"
              name="description"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Container maxWidth="md" sx={{ padding: 0 }}>
          {formData.map((item, index) => (
            <Grid
              container
              marginBottom={1}
              spacing={{ xs: 1, sm: 2 }}
              key={index}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={0.6} sm={0.4}>
                <Typography>{index + 1}.</Typography>
              </Grid>
              <Grid item xs={7} sm={6.6}>
                <TextField
                  label="Label"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="label"
                  value={item.label}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
              <Grid item xs={3.4} sm={4}>
                <TextField
                  label="Value"
                  fullWidth
                  size="small"
                  variant="outlined"
                  name="value"
                  value={item.value}
                  onChange={(e) => handleChange(e, index)}
                />
              </Grid>
              <Grid item xs={1} sm={1}>
                <IconButton
                  onClick={() => handleRemove(index)}
                  sx={{
                    padding: { xs: 0 },
                    margin: { xs: 0 },
                  }}
                >
                  <Delete sx={{ color: 'error.main' }} />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Stack direction="column" spacing={2} sx={{ marginTop: 2, width: '100%' }} alignItems="center">
            <Button startIcon={<Add />} variant="contained" color="greenish" size="small" onClick={handleAdd}>
              {formData.length ? 'Add More' : 'Start Adding'}
            </Button>
          </Stack>
        </Container>
        <Stack direction="row" spacing={2} sx={{ marginTop: 1, width: '100%' }} justifyContent="flex-end">
          <Button size="small" variant="outlined" onClick={() => setSection(null)}>
            Cancel
          </Button>
          {!!formData.length && (
            <Button size="small" variant="contained" type="submit">
              Submit
            </Button>
          )}
        </Stack>
      </form>
    </Card>
  );
}
