import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Countries from './pages/Countries';
import Feeds from './pages/Feeds';
import AddFeed from './pages/AddFeed';
import ViewFeed from './pages/ViewFeed';
import Comments from './pages/Comments';
import Users from './pages/Users';
import Profile from './pages/Profile';
import Admins from './pages/Admins';
import ViewAdmin from './pages/ViewAdmin';
import Promotions from './pages/Promotions';
import Authors from './pages/Authors';
import ViewAuthor from './pages/ViewAuthor';
import CommentView from './pages/CommentView';
import Subscriptions from './pages/Subscriptions';
import AddMail from './pages/AddMail';
import ViewMail from './pages/ViewMail';
import Settings from './pages/Settings';
import Messages from './pages/Messages';
import MessageView from './pages/MessageView';

// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem('accessToken');
  return useRoutes([
    {
      path: '/dashboard',
      element: token ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'category', element: <Countries /> },
        { path: 'promotion', element: <Promotions /> },
        { path: 'feed', element: <Feeds /> },
        { path: 'feed/add', element: <AddFeed /> },
        { path: 'feed/view/:id', element: <ViewFeed /> },
        { path: 'comment', element: <Comments /> },
        { path: 'comment/view/:id', element: <CommentView /> },
        { path: 'user', element: <Users /> },
        { path: 'profile', element: <Profile /> },
        { path: 'account', element: <Admins /> },
        { path: 'account/view/:id', element: <ViewAdmin /> },
        { path: 'author', element: <Authors /> },
        { path: 'author/view/:id', element: <ViewAuthor /> },
        { path: 'subscription', element: <Subscriptions /> },
        { path: 'subscription/mail/add', element: <AddMail /> },
        { path: 'subscription/mail/view/:id', element: <ViewMail /> },
        { path: 'settings', element: <Settings /> },
        { path: 'message', element: <Messages /> },
        { path: 'message/view/:id', element: <MessageView /> },
      ],
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'register',
      element: <Register />,
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: token ? <Navigate to="/dashboard/app" /> : <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);
}
