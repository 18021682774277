import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField,
  MenuItem,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

/// file pond //
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'; // Crops image
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'; // Changes image to match crop
import UpdateOeder from '../UpdateOeder';

registerPlugin(FilePondPluginImagePreview, FilePondPluginImageCrop, FilePondPluginImageTransform);

export default function ImageViewSection({ content, getFeed, feed, index, feedOwner }) {
  const [editMode, setEditMode] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [files, setFiles] = useState([]);
  const truevalue = true;

  const deleteSection = async () => {
    try {
      setButtonLoading(true);
      const res = await axios.delete(`${process.env.REACT_APP_API_URL}/feed/admin/content`, {
        data: {
          id: feed._id,
          contentId: content._id,
        },
      });

      console.log('delete text section', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setButtonLoading(false);
    }
  };

  const handleDelete = () => {
    setDeletePopup(true);
  };

  const formik = useFormik({
    initialValues: {
      id: feed._id,
      contentId: content._id,
      description: content.description,
    },
    onSubmit: async () => {
      const data = new FormData();
      data.append('id', values.id);
      data.append('contentId', values.contentId);
      data.append('description', values.description);
      files.forEach((item) => {
        data.append('image', item.file);
      });
      try {
        const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content/image`, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast(res.data.message);
        getFeed();
        setEditMode(false);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <>
      {/* delete popup  */}
      <Dialog
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Delete Section'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this section?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePopup(false)}>Cancel</Button>
          <Button onClick={deleteSection} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {/* delete popup  */}
      <Card sx={{ padding: 1 }}>
        {!editMode && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {feedOwner && (
                <Stack direction="row" justifyContent="space-between" spacing={1}>
                  <UpdateOeder index={index} getFeed={getFeed} feed={feed} />
                  <Stack spacing={1} direction={'row'}>
                    <LoadingButton
                      loading={buttonLoading}
                      size="small"
                      variant="outlined"
                      color="error"
                      onClick={handleDelete}
                    >
                      Delete
                    </LoadingButton>

                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Stack>
                </Stack>
              )}
            </Grid>
            {content.image.map((item, key) => (
              <Grid key={key} item xs={6} lg={3} md={4} sm={4}>
                <Box
                  component="img"
                  src={item?.fileURL}
                  sx={{
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: '10px',
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ fontFamily: 'Archivo, sans-serif', fontSize: '16px' }}>
                <blockquote>
                  {content.description && content.description?.charAt(0).toUpperCase() + content.description?.slice(1)}
                </blockquote>
              </Typography>
            </Grid>
          </Grid>
        )}
        {editMode && (
          <Box sx={{ paddingX: 1, paddingY: 2 }}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FilePond
                      files={files}
                      onupdatefiles={setFiles}
                      allowMultiple
                      maxFiles={5}
                      credits={false}
                      allowImagePreview
                      // allowImageEdit
                      // server="/api"
                      allowImageCrop={truevalue}
                      allowImageTransform
                      imageCropAspectRatio={'5:3'}
                      name="files"
                      labelIdle="Drag & Drop Images. Keep 5:3 Aspect Ratio"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Description"
                      {...getFieldProps('description')}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-end" spacing={1}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => {
                          setEditMode(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <LoadingButton size="small" variant="contained" type="submit" loading={isSubmitting}>
                        Submit
                      </LoadingButton>
                    </Stack>
                  </Grid>
                </Grid>
              </Form>
            </FormikProvider>
          </Box>
        )}
      </Card>
    </>
  );
}
