import { CircularProgress } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PrivacyPolicyEditor from './PrivacyPolicyEditor';

function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getPrivacyPolicy = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/settings/privacy-policy`);
      console.log('privacy policy', res);
      setPrivacyPolicy(res.data.data.privacyPolicy || '');
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getPrivacyPolicy();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div>
          <PrivacyPolicyEditor data={privacyPolicy} getPrivacyPolicy={getPrivacyPolicy} />
        </div>
      )}
    </>
  );
}

export default PrivacyPolicy;
