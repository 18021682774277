import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

export default function VideoPreview({ item }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="column" justifyContent="center" alignItems="center" sx={{ margin: '0 auto' }}>
        <iframe src={item.videoLink} title={item.title} style={{width:"100%"}} allowFullScreen />
          <Typography
            sx={{
              fontFamily: 'Archivo, sans-serif',
              fontSize: '14px',
              textAlign: 'left',
              width: '100%',
              color: 'grey.700',
              marginTop: '5px',
            }}
            variant="body1"
          >
            {item.title}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
