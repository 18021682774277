import React from 'react';
import { BarAndLineGraphNoCard } from '../sections/@dashboard/app';

export default function BarAndLineGraphPreview({ item }) {
  return (
    <>
      <BarAndLineGraphNoCard
        title={item.title}
        subheader={item.description}
        chartData={item.barGraph.chartData}
        chartLabels={item.barGraph.label}
      />
    </>
  );
}
