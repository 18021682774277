import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography, Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page from '../components/Page';
import Logo from '../components/Logo';
// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    // backgroundColor: '#F5FEFF',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  backgroundColor: '#F5F1EF',
  alignItems: 'center',
  boxShadow: 'rgba(48, 74, 169, 0.2) 5px 5px 50px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page title="Login">
      <RootStyle>
        {mdUp && (
          <SectionStyle>
            <Logo sx={{ mb: 3, height: '70px' }} />
            <Box
              component="img"
              src="/static/login.png"
              alt="login"
              sx={{
                width: '80%',
                height: '50%',
                objectFit: 'contain',
                borderRadius: 1,
              }}
            />
            <Typography
              variant="h3"
              sx={{ mt: 4, mb: 4, color: (theme) => theme.palette.primary.dark, fontFamily: 'Roboto' }}
            >
              Hi, Welcome Back
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Typography
              gutterBottom
              textAlign="center"
              sx={{
                width: '100%',
                color: (theme) => theme.palette.primary.dark,
                fontSize: '24px',
                fontFamily: 'Roboto',
                fontWeight: 500,
              }}
            >
              Sign in to OzPolitika
            </Typography>

            <Typography sx={{ color: (theme) => theme.palette.primary.main, mb: 5, width: '100%' }} textAlign="center">
              Enter your details below
            </Typography>

            {/* <AuthSocial /> */}

            <LoginForm />
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
