import * as React from 'react';
import moment from 'moment';
import Menu from '@mui/material/Menu';
import { Formik, Form } from 'formik';
import { IconButton, TextField, Typography, Button, Stack, Box, MenuItem } from '@mui/material';
import Iconify from '../Iconify';

const Filter = ({ toggleFilter, applyFilters }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <Box style={{ display: 'flex', justifyContent: 'end' }}>
          <IconButton onClick={handleClick}>
            <Iconify icon="ic:round-filter-list" />
            <Typography variant="h6"> Filter</Typography>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <Formik
              initialValues={{
                name: '',
                isDisabled: '',
              }}
              onSubmit={async (values) => {
                const updateValues = {
                  ...values,
                  startDate: values.startDate ? new Date(moment(values.startDate).startOf('day')) : null,
                  endDate: values.endDate ? new Date(moment(values.endDate).endOf('day')) : null,
                };
                applyFilters(updateValues);
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                <Form style={{ padding: '10px' }}>
                  <Stack direction="column">
                    <TextField
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="name"
                      name="name"
                      type="name"
                      label="Name"
                      value={values.name}
                      onChange={handleChange}
                    />  
                    <TextField
                      select
                      style={{ paddingBottom: '10px' }}
                      fullWidth
                      id="isDisabled"
                      name="isDisabled"
                      label="Status"
                      value={values.isDisabled}
                      onChange={handleChange}
                    >
                      <MenuItem value="false">ACTIVE</MenuItem>
                      <MenuItem value="true">DISABLED</MenuItem>
                    </TextField>
                    <Button variant="contained" type="submit" style={{ marginTop: '10px' }}>
                      Filter
                    </Button>
                  </Stack>
                </Form>
              )}
            </Formik>
          </Menu>
        </Box>
      </div>
    </>
  );
};

export default Filter;
