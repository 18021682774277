import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

export default function VideoFilePreview({ item }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ margin: '0 auto' }}
        >
          <video src={item.video} controls style={{ width: '100%', height: '100%' }} muted />
          <Typography
            sx={{
              fontFamily: ' Archivo, sans-serif !important',
              fontSize: '14px',
              textAlign: 'left',
              width: '100%',
              color: 'grey.700',
              marginTop: '5px',
            }}
            variant="body1"
          >
            {item.description}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
}
