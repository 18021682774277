import { useState } from 'react';
// material
import { Container, Typography, Card, Tab, Box, Stack } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';

// components
import Page from '../components/Page';
import Subscribers from '../components/Subscribers';
import Mails from '../components/Mails';
import BackButton from '../components/BackButton';

export default function Subscriptions() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Page title="Subscriptions">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h4" gutterBottom>
            Subscriptions
          </Typography>
        </Stack>
        <Card>
          <Box sx={{ width: '100%', marginTop: '20px' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange}>
                  <Tab label="Mails" value="1" />
                  <Tab label="Subscribers" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Mails />
              </TabPanel>
              <TabPanel value="2">
                <Subscribers />
              </TabPanel>
            </TabContext>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
