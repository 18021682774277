import { MenuItem, TextField } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';

function UpdateOeder({ index, feed, getFeed }) {
  const updateOrder = async (e) => {
    try {
      const res = await axios.put(`${process.env.REACT_APP_API_URL}/feed/admin/content-order`, {
        id: feed._id,
        currentIndex: index,
        newIndex: e.target.value,
      });
      console.log('update order', res);
      getFeed();
      toast(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <TextField select size="small" label="Order" value={index} onChange={updateOrder} sx={{ width: '100px' }}>
      {feed?.content?.map((item, index) => (
        <MenuItem key={index} value={index}>
          {index + 1}
        </MenuItem>
      ))}
    </TextField>
  );
}

export default UpdateOeder;
