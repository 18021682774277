import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import axios from 'axios';

const PopupDelete = (props) => {
  console.log(props);
  const deleteFeed = () => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/comment/admin/delete`, {
        params: {
          id: props.item._id,
        },
      })
      .then((res) => {
        props.handleClose(true, res.data.message);
      })
      .catch((err) => {
        props.handleClose(
          false,
          err && err.response && err.response.data && err.response.data.message
            ? err.data.response.message
            : err.message
        );
      });
  };
  const open = true;
  return (
    <>
      <Dialog onClose={() => props.handleClose(false)} open={open}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this Comment?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleClose(false)}>Cancel</Button>
          <Button onClick={deleteFeed}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PopupDelete;
